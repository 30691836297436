import React, { useState, useEffect } from 'react';
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaFacebook, FaTwitter, FaInstagram, FaArrowUp } from 'react-icons/fa';
import 'tailwindcss/tailwind.css';

const ContactUsPage = () => {
  const [showTopButton, setShowTopButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShowTopButton(true);
      } else {
        setShowTopButton(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <HeroSection />
      <div className="container mx-auto p-8 bg-white shadow-lg rounded-lg mt-10">
        <h1 className="text-4xl font-bold mb-8 text-center text-blue-600">Contact Us</h1>
        <ContactForm />
        <ContactInformation />
        <MapSection />
        <OpeningHours />
        <FAQ />
        <FeedbackForm />
        <AdditionalContacts />
        {showTopButton && (
          <button
            onClick={scrollToTop}
            className="fixed bottom-8 right-8 bg-blue-500 text-white p-3 rounded-full shadow-lg hover:bg-blue-700 transition-colors duration-300"
          >
            <FaArrowUp />
          </button>
        )}
      </div>
    </div>
  );
};

const HeroSection = () => (
  <div className="relative bg-blue-500 text-white text-center py-20 mb-10">
    <div className="absolute inset-0 opacity-25">
      <img src={`${process.env.REACT_APP_API_URL}/Media/Images/herbs_edited-768x576.jpg`} alt="Contact Us" className="w-full h-full object-cover" />
    </div>
    <div className="relative z-10">
      <h1 className="text-5xl font-bold mb-4">Get in Touch with Us</h1>
      <p className="text-xl mb-8">We're here to help and answer any questions you might have. We look forward to hearing from you!</p>
    </div>
  </div>
);

const ContactForm = () => {
  const [formData, setFormData] = useState({ name: '', email: '', subject: '', message: '' });
  const [errors, setErrors] = useState({});

  const validate = () => {
    let tempErrors = {};
    tempErrors.name = formData.name ? "" : "This field is required.";
    tempErrors.email = (/\S+@\S+\.\S+/).test(formData.email) ? "" : "Email is not valid.";
    tempErrors.subject = formData.subject ? "" : "This field is required.";
    tempErrors.message = formData.message ? "" : "This field is required.";
    setErrors(tempErrors);
    return Object.values(tempErrors).every(x => x === "");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      console.log('Form submitted', formData);
      // Submit the form
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mb-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="mb-4">
          <label htmlFor="name" className="block text-gray-700">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            aria-required="true"
            aria-invalid={errors.name ? "true" : "false"}
            aria-describedby="name-error"
            required
          />
          {errors.name && <span id="name-error" className="text-red-500">{errors.name}</span>}
        </div>
        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-700">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            aria-required="true"
            aria-invalid={errors.email ? "true" : "false"}
            aria-describedby="email-error"
            required
          />
          {errors.email && <span id="email-error" className="text-red-500">{errors.email}</span>}
        </div>
      </div>
      <div className="mb-4">
        <label htmlFor="subject" className="block text-gray-700">Subject</label>
        <input
          type="text"
          id="subject"
          name="subject"
          value={formData.subject}
          onChange={handleChange}
          className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          aria-required="true"
          aria-invalid={errors.subject ? "true" : "false"}
          aria-describedby="subject-error"
          required
        />
        {errors.subject && <span id="subject-error" className="text-red-500">{errors.subject}</span>}
      </div>
      <div className="mb-4">
        <label htmlFor="message" className="block text-gray-700">Message</label>
        <textarea
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          rows="5"
          aria-required="true"
          aria-invalid={errors.message ? "true" : "false"}
          aria-describedby="message-error"
          required
        ></textarea>
        {errors.message && <span id="message-error" className="text-red-500">{errors.message}</span>}
      </div>
      <button type="submit" className="bg-blue-500 text-white px-6 py-3 rounded-md shadow-md hover:bg-blue-700 transition-colors duration-300">
        Send Message
      </button>
    </form>
  );
};

const ContactInformation = () => (
  <div className="mb-8">
    <h2 className="text-2xl font-bold text-teal-500 mb-4">Contact Information</h2>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      <div className="flex items-center mb-4">
        <FaPhone className="text-blue-500 mr-2" />
        <p className="leading-relaxed">Phone: <a href="tel:07921 65385" className="text-blue-500 hover:underline">07921 65385</a></p>
      </div>
      <div className="flex items-center mb-4">
        <FaEnvelope className="text-blue-500 mr-2" />
        <p className="leading-relaxed">Email: <a href="mailto:dgleicester@gmail.com" className="text-blue-500 hover:underline">dgleicester@gmail.com</a></p>
      </div>
      <div className="flex items-center mb-4">
        <FaMapMarkerAlt className="text-blue-500 mr-2" />
        <p className="leading-relaxed">Address: Leicester, UK</p>
      </div>
    </div>
  </div>
);

const MapSection = () => (
  <div className="mb-8">
    <h2 className="text-2xl font-bold text-teal-500 mb-4">Our Location</h2>
    <div className="w-full h-64 rounded-lg overflow-hidden shadow-md">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2419.45513281294!2d-1.129715584493713!3d52.66981853286812!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487761b0b92f3fff%3A0x15e19ce61b56cb71!2sRoman%20Rd%2C%20Birstall%2C%20Leicester!5e0!3m2!1sen!2suk!4v1678050242519!5m2!1sen!2suk"
        width="100%"
        height="100%"
        allowFullScreen=""
        loading="lazy"
        title="Google Maps"
      ></iframe>
    </div>
  </div>
);

const OpeningHours = () => (
  <div className="mb-8">
    <h2 className="text-2xl font-bold text-teal-500 mb-4">Opening Hours</h2>
    <ul className="list-disc list-inside pl-4 leading-relaxed">
      <li>Monday - Friday: 9:00 AM - 5:00 PM</li>
      <li>Sunday: Closed</li>
    </ul>
  </div>
);

const FAQ = () => {
  const faqs = [
    {
      question: "How can I join the Diabetes Self-Help Group?",
      answer: "You can join our group by filling out the membership form on our website or by contacting us directly through phone or email.",
    },
    {
      question: "What services do you offer?",
      answer: "We offer a range of services including diabetes education, support groups, health check-ups, and workshops.",
    },
    {
      question: "Can I volunteer with your organization?",
      answer: "Yes, we welcome volunteers! Please reach out to us via the contact form or email us directly for more information on how you can help.",
    },
  ];

  return (
    <div className="faq bg-gray-100 p-4 rounded-lg shadow-inner mb-8">
      <h3 className="text-xl font-bold text-teal-500 mb-4">Frequently Asked Questions</h3>
      {faqs.map((faq, index) => (
        <div key={index} className="mb-4">
          <h4 className="font-semibold text-gray-800">{faq.question}</h4>
          <p className="text-gray-700 leading-relaxed">{faq.answer}</p>
        </div>
      ))}
    </div>
  );
};

const FeedbackForm = () => {
  const [feedback, setFeedback] = useState('');

  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };

  const handleFeedbackSubmit = (e) => {
    e.preventDefault();
    console.log('Feedback submitted:', feedback);
    setFeedback('');
  };

  return (
    <div className="mb-8">
      <h2 className="text-2xl font-bold text-teal-500 mb-4">Feedback</h2>
      <form onSubmit={handleFeedbackSubmit} className="mb-4">
        <div className="mb-4">
          <label htmlFor="feedback" className="block text-gray-700">Your Feedback</label>
          <textarea
            id="feedback"
            value={feedback}
            onChange={handleFeedbackChange}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            rows="4"
            required
          ></textarea>
        </div>
        <button type="submit" className="bg-blue-500 text-white px-6 py-3 rounded-md shadow-md hover:bg-blue-700 transition-colors duration-300">
          Submit Feedback
        </button>
      </form>
    </div>
  );
};

const AdditionalContacts = () => (
  <div className="mb-8">
    <h2 className="text-2xl font-bold text-teal-500 mb-4">Connect with Us</h2>
    <div className="flex space-x-4">
      <a href="https://www.facebook.com/SHGLeicester/" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700">
        <FaFacebook size={32} />
      </a>
      <a href="https://twitter.com/shgleicester?lang=en-gb" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700">
        <FaTwitter size={32} />
      </a>
      <a href="https://www.instagram.com/shgleicester/" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700">
        <FaInstagram size={32} />
      </a>
    </div>
  </div>
);

export default ContactUsPage;
