import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaDownload } from 'react-icons/fa';

const ArchivedNewsletters = () => {
  const [archivedNewsletters, setArchivedNewsletters] = useState([]);

  useEffect(() => {
    const fetchArchivedNewsletters = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/pdf-uploads`);
        setArchivedNewsletters(response.data);
      } catch (error) {
        console.error('Error fetching archived newsletters:', error);
      }
    };

    fetchArchivedNewsletters();
  }, []);

  return (
    <div className="container mx-auto my-8 p-4">
      <h2 className="text-3xl font-bold mb-4 text-center text-blue-900">Archived Newsletters</h2>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-16 max-w-7xl mx-auto px-8">
        {archivedNewsletters.map((newsletter) => (
          <div key={newsletter.id} className="bg-gray-100 p-8 rounded-lg shadow-lg">
            <h3 className="text-3xl font-bold mb-4 text-blue-900">{newsletter.title}</h3>
            <iframe
              src={`${process.env.REACT_APP_API_URL}${newsletter.path}`}
              width="100%"
              height="200px"
              className="mb-4"
              title={`Preview of ${newsletter.title}`}
            ></iframe>
            <div className="flex justify-between items-center mt-4">
              <a
                href={`${process.env.REACT_APP_API_URL}${newsletter.path}`}
                target="_blank"
                rel="noopener noreferrer"
                className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300"
              >
                View
              </a>
              <a
                href={`${process.env.REACT_APP_API_URL}${newsletter.path}`}
                download
                className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700 transition duration-300 flex items-center"
              >
                <FaDownload className="mr-2" /> Download
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ArchivedNewsletters;
