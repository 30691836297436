import React, { useState } from 'react';

const GlucoseTracker = () => {
  const [glucoseLevel, setGlucoseLevel] = useState('');
  const [readings, setReadings] = useState([]);
  const [status, setStatus] = useState('');

  const addReading = (e) => {
    e.preventDefault();
    if (glucoseLevel) {
      const newReading = { level: parseInt(glucoseLevel, 10), date: new Date().toLocaleString() };
      setReadings([...readings, newReading]);
      setStatus(evaluateGlucoseLevel(newReading.level));
      setGlucoseLevel('');
    }
  };

  const evaluateGlucoseLevel = (level) => {
    if (level < 70) {
      return 'Low (Hypoglycemia)';
    } else if (level >= 70 && level <= 99) {
      return 'Normal';
    } else if (level >= 100 && level <= 125) {
      return 'Prediabetes';
    } else {
      return 'Diabetes';
    }
  };

  return (
    <div className="bg-white p-8 rounded-lg shadow-lg">
      <h3 className="text-3xl font-bold mb-4">Glucose Level Identifier</h3>
      <form onSubmit={addReading}>
        <div className="mb-4">
          <label className="block text-left mb-2">Glucose Level (mg/dL):</label>
          <input
            type="number"
            value={glucoseLevel}
            onChange={(e) => setGlucoseLevel(e.target.value)}
            className="w-full px-4 py-2 border rounded-md"
            required
          />
        </div>
        <button type="submit" className="bg-blue-600 text-white px-6 py-3 rounded-full shadow-lg hover:bg-blue-700 transition duration-300">
          Add Reading
        </button>
      </form>
      <div className="mt-4">
        <h4 className="text-2xl font-bold mb-2">Status</h4>
        <p className={`text-xl ${status.includes('Low') ? 'text-yellow-600' : status.includes('Normal') ? 'text-green-600' : status.includes('Prediabetes') ? 'text-orange-600' : 'text-red-600'}`}>
          {status}
        </p>
      </div>
      <div className="mt-4">
        <h4 className="text-2xl font-bold mb-2">Previous Readings</h4>
        <ul>
          {readings.map((reading, index) => (
            <li key={index} className="border-b py-2">
              {reading.date}: {reading.level} mg/dL
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-4">
        <h4 className="text-2xl font-bold mb-2">Glucose Level Scale</h4>
        <div className="flex justify-between mt-2">
          <div className="flex flex-col items-center">
            <div className="bg-yellow-600 h-6 w-6 rounded-full"></div>
            <span>Low</span>
          </div>
          <div className="flex flex-col items-center">
            <div className="bg-green-600 h-6 w-6 rounded-full"></div>
            <span>Normal</span>
          </div>
          <div className="flex flex-col items-center">
            <div className="bg-orange-600 h-6 w-6 rounded-full"></div>
            <span>Prediabetes</span>
          </div>
          <div className="flex flex-col items-center">
            <div className="bg-red-600 h-6 w-6 rounded-full"></div>
            <span>Diabetes</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlucoseTracker;
