import React from 'react';
import { FaTwitter, FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import Countdown from 'react-countdown';
import Chart from 'chart.js/auto';
import { Line } from 'react-chartjs-2';

const LivePreview = ({ sections, deviceView }) => {
  const renderSection = (section) => {
    switch (section.type) {
      case 'title':
        return <h1 className="text-3xl font-bold">{section.content}</h1>;
      case 'text':
        return <div dangerouslySetInnerHTML={{ __html: section.content }} />;
      case 'image':
        return <img src={section.content} alt="Section" className="w-full" />;
      case 'video':
        return <iframe src={section.content} title="Video" className="w-full h-64" frameBorder="0" allowFullScreen></iframe>;
      case 'button':
        return <button className="px-4 py-2 bg-blue-500 text-white rounded">{section.content}</button>;
      case 'icon':
        return <img src={section.content} alt="Icon" className="icon-preview" />;
      case 'column':
        return (
          <div className="grid grid-cols-2 gap-4">
            <div className="p-2 bg-gray-200">Column 1</div>
            <div className="p-2 bg-gray-200">Column 2</div>
          </div>
        );
      case 'social':
        return (
          <div className="flex space-x-4">
            <FaTwitter size={24} />
            <FaFacebook size={24} />
            <FaInstagram size={24} />
            <FaLinkedin size={24} />
          </div>
        );
      case 'form':
        return (
          <form className="space-y-2">
            <input type="text" placeholder="Name" className="w-full p-2 border rounded" />
            <input type="email" placeholder="Email" className="w-full p-2 border rounded" />
            <button type="submit" className="w-full p-2 bg-blue-500 text-white rounded">Submit</button>
          </form>
        );
      case 'countdown':
        return <Countdown date={Date.now() + 1000000000} />;
      case 'map':
        return (
          <iframe
            src={`https://www.google.com/maps/embed/v1/place?key=YOUR_GOOGLE_MAPS_API_KEY&q=${encodeURIComponent(section.content)}`}
            className="w-full h-64"
            allowFullScreen
            title="Google Maps"
          ></iframe>
        );
      case 'chart':
        const data = {
          labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
          datasets: [
            {
              label: 'Sample Data',
              data: [65, 59, 80, 81, 56, 55, 40],
              fill: false,
              backgroundColor: 'rgba(75,192,192,0.4)',
              borderColor: 'rgba(75,192,192,1)',
            },
          ],
        };
        return <Line data={data} />;
      case 'quote':
        return (
          <blockquote className="p-4 border-l-4 border-blue-500 italic bg-gray-100 text-gray-600">
            {section.content}
          </blockquote>
        );
      default:
        return null;
    }
  };

  return (
    <div className={`live-preview ${deviceView}`}>
      {sections.map((section, index) => (
        <div key={index} className="mb-4 p-4 bg-white shadow-md rounded">
          {renderSection(section)}
        </div>
      ))}
    </div>
  );
};

export default LivePreview;
