import React, { useEffect, useState, useCallback } from 'react';
import api from '../api'; // Import custom axios instance
import { useCart } from '../components/contexts/CartContext';
import AddProductForm from '../components/AddProductForm';
import EditProductForm from '../components/EditProductForm'; // Ensure this is the correct path
import { useUser } from '../components/contexts/UserContext';
import { debounce } from 'lodash';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaPlus, FaShoppingCart, FaHeart, FaTimes, FaEdit, FaTrash } from 'react-icons/fa';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // Ensure to bind modal to your appElement

const Store = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [sortOption, setSortOption] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isAddProductModalOpen, setIsAddProductModalOpen] = useState(false);
  const [isEditProductModalOpen, setIsEditProductModalOpen] = useState(false);
  const [isProductDetailsModalOpen, setIsProductDetailsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const { addItemToCart } = useCart();
  const { user, token } = useUser();

  const cache = {};

  const fetchCategories = async () => {
    if (cache['categories']) {
      setCategories(cache['categories']);
      return;
    }
    try {
      const response = await api.get('/products'); // Adjusted endpoint
      const fetchedCategories = response.data.map(product => product.category);
      const uniqueCategories = [...new Set(fetchedCategories)];
      setCategories(uniqueCategories);
      cache['categories'] = uniqueCategories;
    } catch (error) {
      if (error.response && error.response.status === 429) {
        console.error('Rate limit exceeded. Please try again later.');
      } else {
        console.error('Failed to fetch categories:', error);
      }
    }
  };

  const fetchProducts = async () => {
    const cacheKey = `products_${selectedCategory}_${sortOption}_${currentPage}`;
    if (cache[cacheKey]) {
      setProducts(cache[cacheKey].products);
      setTotalPages(cache[cacheKey].totalPages);
      return;
    }
    try {
      const response = await api.get('/products', {
        params: {
          category: selectedCategory,
          sort: sortOption,
          page: currentPage,
        },
      });
      console.log('Fetched products:', response.data); // Debug log
      if (Array.isArray(response.data)) {
        setProducts(response.data);
        setTotalPages(1); // Assuming pagination is not implemented in the API
        cache[cacheKey] = {
          products: response.data,
          totalPages: 1,
        };
      } else {
        console.error('Unexpected response structure:', response.data);
      }
    } catch (error) {
      if (error.response && error.response.status === 429) {
        console.error('Rate limit exceeded. Please try again later.');
      } else {
        console.error('Failed to fetch products:', error);
      }
    }
  };

  const debouncedFetchCategories = useCallback(debounce(fetchCategories, 1000), []);
  const debouncedFetchProducts = useCallback(debounce(fetchProducts, 1000), [selectedCategory, sortOption, currentPage]);

  useEffect(() => {
    debouncedFetchCategories();
  }, [debouncedFetchCategories]);

  useEffect(() => {
    debouncedFetchProducts();
  }, [debouncedFetchProducts]);

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setCurrentPage(1);
  };

  const handleSortChange = (event) => {
    setSortOption(event.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleProductAdded = (newProduct) => {
    setProducts([newProduct, ...products]);
    setIsAddProductModalOpen(false);
  };

  const handleProductEdited = (editedProduct) => {
    setProducts(products.map(p => p.id === editedProduct.id ? editedProduct : p));
    setIsEditProductModalOpen(false);
  };

  const handleAddToCart = (product) => {
    const item = { productId: product.id, quantity: 1 };
    addItemToCart(item);
    toast.success(`${product.name} added to cart!`, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setIsProductDetailsModalOpen(true);
  };

  const handleWishlist = (product) => {
    toast.info(`${product.name} added to wishlist!`);
  };

  const handleEditProduct = (product) => {
    setSelectedProduct(product);
    setIsEditProductModalOpen(true);
  };

  const handleDeleteProduct = async (productId) => {
    try {
      await api.delete(`/products/${productId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProducts(products.filter(p => p.id !== productId));
      toast.success('Product deleted successfully!');
    } catch (error) {
      console.error('Failed to delete product:', error);
      toast.error('Failed to delete product.');
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <ToastContainer />
      <h1 className="text-3xl font-bold mb-8">Store</h1>

      <div className="flex justify-between mb-4">
        <div className="flex items-center">
          <label className="mr-2">Category:</label>
          <select value={selectedCategory} onChange={handleCategoryChange} className="border p-2 rounded">
            <option value="">All</option>
            {categories.length > 0 && categories.map((category, index) => (
              <option key={index} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>

        <div className="flex items-center">
          <label className="mr-2">Sort by:</label>
          <select value={sortOption} onChange={handleSortChange} className="border p-2 rounded">
            <option value="">None</option>
            <option value="price">Price</option>
            <option value="name">Name</option>
          </select>
        </div>

        {user && user.role === 'admin' && (
          <button
            onClick={() => setIsAddProductModalOpen(true)}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded flex items-center"
          >
            <FaPlus className="mr-2" /> Add Product
          </button>
        )}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-8">
        {products.length > 0 ? (
          products.map((product) => (
            <div
              key={product.id}
              className="border rounded-lg p-4 shadow-lg transform transition-transform hover:scale-105 hover:shadow-xl cursor-pointer"
              onClick={() => handleProductClick(product)}
            >
              <img
                src={`${process.env.REACT_APP_API_URL}/${product.imageUrl}`} // Updated URL
                alt={product.name}
                className="w-full h-48 object-cover mb-4 rounded-lg"
              />
              <h2 className="text-xl font-bold mb-2">{product.name}</h2>
              <p className="text-gray-700 mb-4">{product.description}</p>
              <p className="text-lg font-semibold mb-4">£{product.price}</p>
              <div className="flex justify-between items-center">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAddToCart(product);
                  }}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center"
                >
                  <FaShoppingCart className="mr-2" /> Add to Cart
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleWishlist(product);
                  }}
                  className="bg-pink-500 hover:bg-pink-700 text-white font-bold py-2 px-4 rounded flex items-center"
                >
                  <FaHeart className="mr-2" /> Wishlist
                </button>
                {user && user.role === 'admin' && (
                  <div className="flex items-center space-x-2">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditProduct(product);
                      }}
                      className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded flex items-center"
                    >
                      <FaEdit className="mr-2" /> Edit
                    </button>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteProduct(product.id);
                      }}
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded flex items-center"
                    >
                      <FaTrash className="mr-2" /> Delete
                    </button>
                  </div>
                )}
              </div>
            </div>
          ))
        ) : (
          <p>No products available</p>
        )}
      </div>

      <div className="flex justify-center mt-8">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            className={`mx-1 px-3 py-1 border rounded ${index + 1 === currentPage ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'}`}
          >
            {index + 1}
          </button>
        ))}
      </div>

      {isProductDetailsModalOpen && selectedProduct && (
        <Modal
          isOpen={isProductDetailsModalOpen}
          onRequestClose={() => setIsProductDetailsModalOpen(false)}
          contentLabel="Product Details"
          className="relative bg-white rounded-lg shadow-lg p-8 w-full max-w-lg mx-auto my-8 z-50"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-40"
        >
          <div className="flex justify-end">
            <button onClick={() => setIsProductDetailsModalOpen(false)} className="text-gray-500 hover:text-gray-700">
              <FaTimes className="w-6 h-6" />
            </button>
          </div>
          <h2 className="text-3xl font-bold mb-4">{selectedProduct.name}</h2>
          <div className="mb-4">
            <img
              src={`${process.env.REACT_APP_API_URL}/${selectedProduct.imageUrl}`} // Updated URL
              alt={selectedProduct.name}
              className="w-full h-48 object-cover mb-4 rounded-lg"
            />
          </div>
          <p className="text-gray-700 mb-4">{selectedProduct.description}</p>
          <p className="text-lg font-semibold mb-4">£{selectedProduct.price}</p>
          <button
            onClick={() => handleAddToCart(selectedProduct)}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center"
          >
            <FaShoppingCart className="mr-2" /> Add to Cart
          </button>
        </Modal>
      )}

      {isAddProductModalOpen && (
        <AddProductForm
          onClose={() => setIsAddProductModalOpen(false)}
          onProductAdded={handleProductAdded}
        />
      )}

      {isEditProductModalOpen && selectedProduct && (
        <EditProductForm
          product={selectedProduct}
          onClose={() => setIsEditProductModalOpen(false)}
          onProductEdited={handleProductEdited}
        />
      )}
    </div>
  );
};

export default Store;
