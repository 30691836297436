import React from 'react';
import { FaHeading, FaAlignLeft, FaImage, FaVideo, FaRegSquare, FaIcons, FaColumns, FaAddressCard, FaLink, FaCalendarAlt, FaMapMarkerAlt, FaChartBar, FaQuoteRight } from 'react-icons/fa';

const SectionType = {
  TITLE: 'title',
  TEXT: 'text',
  IMAGE: 'image',
  VIDEO: 'video',
  BUTTON: 'button',
  ICON: 'icon',
  COLUMN: 'column',
  SOCIAL: 'social',
  FORM: 'form',
  COUNTDOWN: 'countdown',
  MAP: 'map',
  CHART: 'chart',
  QUOTE: 'quote',
};

const WidgetMenu = ({ onAddSection }) => {
  return (
    <div className="widget-menu w-full h-full bg-gray-100 p-4 space-y-4 border-r overflow-y-auto">
      <h2 className="text-xl font-bold mb-4">Add Widgets</h2>
      <div className="space-y-2">
        <button onClick={() => onAddSection(SectionType.TITLE)} className="w-full flex items-center p-2 bg-blue-500 text-white rounded hover:bg-blue-600">
          <FaHeading className="mr-2" />
          Add Title
        </button>
        <button onClick={() => onAddSection(SectionType.TEXT)} className="w-full flex items-center p-2 bg-green-500 text-white rounded hover:bg-green-600">
          <FaAlignLeft className="mr-2" />
          Add Text
        </button>
        <button onClick={() => onAddSection(SectionType.IMAGE)} className="w-full flex items-center p-2 bg-red-500 text-white rounded hover:bg-red-600">
          <FaImage className="mr-2" />
          Add Image
        </button>
        <button onClick={() => onAddSection(SectionType.VIDEO)} className="w-full flex items-center p-2 bg-purple-500 text-white rounded hover:bg-purple-600">
          <FaVideo className="mr-2" />
          Add Video
        </button>
        <button onClick={() => onAddSection(SectionType.BUTTON)} className="w-full flex items-center p-2 bg-yellow-500 text-white rounded hover:bg-yellow-600">
          <FaRegSquare className="mr-2" />
          Add Button
        </button>
        <button onClick={() => onAddSection(SectionType.ICON)} className="w-full flex items-center p-2 bg-indigo-500 text-white rounded hover:bg-indigo-600">
          <FaIcons className="mr-2" />
          Add Icon
        </button>
        <button onClick={() => onAddSection(SectionType.COLUMN)} className="w-full flex items-center p-2 bg-pink-500 text-white rounded hover:bg-pink-600">
          <FaColumns className="mr-2" />
          Add Column
        </button>
        <button onClick={() => onAddSection(SectionType.SOCIAL)} className="w-full flex items-center p-2 bg-teal-500 text-white rounded hover:bg-teal-600">
          <FaAddressCard className="mr-2" />
          Add Social
        </button>
        <button onClick={() => onAddSection(SectionType.FORM)} className="w-full flex items-center p-2 bg-orange-500 text-white rounded hover:bg-orange-600">
          <FaLink className="mr-2" />
          Add Form
        </button>
        <button onClick={() => onAddSection(SectionType.COUNTDOWN)} className="w-full flex items-center p-2 bg-gray-500 text-white rounded hover:bg-gray-600">
          <FaCalendarAlt className="mr-2" />
          Add Countdown
        </button>
        <button onClick={() => onAddSection(SectionType.MAP)} className="w-full flex items-center p-2 bg-blue-400 text-white rounded hover:bg-blue-500">
          <FaMapMarkerAlt className="mr-2" />
          Add Map
        </button>
        <button onClick={() => onAddSection(SectionType.CHART)} className="w-full flex items-center p-2 bg-green-400 text-white rounded hover:bg-green-500">
          <FaChartBar className="mr-2" />
          Add Chart
        </button>
        <button onClick={() => onAddSection(SectionType.QUOTE)} className="w-full flex items-center p-2 bg-red-400 text-white rounded hover:bg-red-500">
          <FaQuoteRight className="mr-2" />
          Add Quote
        </button>
      </div>
    </div>
  );
};

export default WidgetMenu;
