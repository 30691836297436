// src/components/RecipeCard.js
import React from 'react';

const RecipeCard = ({ image, title, description, url }) => {
  return (
    <div className="bg-white p-8 rounded-lg shadow-lg transform transition-transform hover:scale-105 duration-300 hover:shadow-xl">
      <img src={image} alt="Recipe" className="w-full h-48 object-cover rounded-t-lg mb-4" />
      <h3 className="text-2xl font-bold mb-2">{title}</h3>
      <p className="text-lg">{description}</p>
      <a href={url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline mt-2 block">Read More</a>
    </div>
  );
};

export default RecipeCard;
