import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const UserContext = createContext({
  user: null,
  token: null,
  login: () => {},
  logout: () => {}
});

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem('token') || null);

  const fetchUserData = useCallback(async () => {
    if (!token) return;
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/current_user`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.data) {
        setUser(response.data);
      }
    } catch (error) {
      console.error('Failed to fetch user:', error);
      setUser(null);
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      fetchUserData();
    }
  }, [token, fetchUserData]);

  const login = async (credentials) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/users/login`, credentials);
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        setToken(response.data.token);
        setUser(response.data.user);
      } else {
        throw new Error('Login failed'); // Explicitly throw an error if login fails
      }
    } catch (error) {
      throw new Error('Login failed: ' + error.message); // Throw the error so it can be caught in the login form
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    setToken(null);
    setUser(null);
  };

  return (
    <UserContext.Provider value={{ user, token, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);

export default UserContext;
