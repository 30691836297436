import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactModal from 'react-modal';
import { FaTimes, FaTrash, FaArrowUp, FaArrowDown, FaDownload, FaEye, FaPaperPlane, FaUpload } from 'react-icons/fa';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import ImageSlider from '../components/ImageSlider';
import ReCAPTCHA from 'react-google-recaptcha';
import BmiCalculator from '../components/BmiCalculator';
import GlucoseTracker from '../components/GlucoseTracker';
import RecipeCard from '../components/RecipeCard';
import DynamicGraph from '../components/DynamicGraph';
import Chatbot from '../components/Chatbot';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../components/contexts/UserContext';
import {
  fetchBlogPosts,
  fetchNews,
  fetchEvents,
  fetchResearchArticles,
  fetchPersonalStories,
  fetchNewsletters,
  createNewsletter,
  signUpNewsletter
} from '../api';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import jsPDF from 'jspdf';

ReactModal.setAppElement('#root');

const SectionType = {
  TITLE: 'title',
  TEXT: 'text',
  IMAGE: 'image',
  TESTIMONIAL: 'testimonial',
  CONTACT: 'contact',
  PDF: 'pdf'
};

const DraggableSection = ({ id, index, moveSection, removeSection, children }) => {
  const ref = React.useRef(null);
  const [, drop] = useDrop({
    accept: 'section',
    hover(item) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      moveSection(dragIndex, hoverIndex);
      item.index = hoverIndex;
    }
  });
  const [{ isDragging }, drag] = useDrag({
    type: 'section',
    item: { id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <div ref={ref} className="draggable-section" style={{ opacity: isDragging ? 0.5 : 1 }}>
      {children}
      <button onClick={() => removeSection(index)} className="absolute top-0 right-0 bg-red-600 text-white p-1 rounded-full">
        <FaTrash />
      </button>
      <button onClick={() => moveSection(index, index - 1)} className="absolute top-0 left-0 bg-gray-600 text-white p-1 rounded-full">
        <FaArrowUp />
      </button>
      <button onClick={() => moveSection(index, index + 1)} className="absolute bottom-0 left-0 bg-gray-600 text-white p-1 rounded-full">
        <FaArrowDown />
      </button>
    </div>
  );
};

const HomePage = ({ addNotification }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [newSlideModalIsOpen, setNewSlideModalIsOpen] = useState(false);
  const [newEventModalIsOpen, setNewEventModalIsOpen] = useState(false);
  const [editEventModalIsOpen, setEditEventModalIsOpen] = useState(false);
  const [newsletterModalIsOpen, setNewsletterModalIsOpen] = useState(false);
  const [createNewsletterModalIsOpen, setCreateNewsletterModalIsOpen] = useState(false);
  const [previewModalIsOpen, setPreviewModalIsOpen] = useState(false);
  const [newsletterContent, setNewsletterContent] = useState([]);
  const [newsletterTemplate, setNewsletterTemplate] = useState('default');
  const [newsletterImages, setNewsletterImages] = useState([]);
  const [selectedSegment, setSelectedSegment] = useState('all');
  const [blogPosts, setBlogPosts] = useState([]);
  const [news, setNews] = useState([]);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [events, setEvents] = useState([]);
  const [researchArticles, setResearchArticles] = useState([]);
  const [personalStories, setPersonalStories] = useState([]);
  const [slideshows, setSlideshows] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(null);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [imageFiles, setImageFiles] = useState([]);
  const [newImageFiles, setNewImageFiles] = useState([]);
  const [eventImage, setEventImage] = useState(null);
  const [recipes, setRecipes] = useState([]);
  const [newsletters, setNewsletters] = useState([]);
  const [pdfFile, setPdfFile] = useState(null);
  const [archiveModalIsOpen, setArchiveModalIsOpen] = useState(false);
  const [archivedNewsletters, setArchivedNewsletters] = useState([]);
  const { user, token } = useUser();
  const navigate = useNavigate();

  const isAdmin = user && user.role.toLowerCase() === 'admin';

  const fetchSlideshows = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/slideshows`);
      setSlideshows(response.data);
    } catch (error) {
      console.error('Error fetching slideshows:', error);
    }
  };

  const fetchRecipes = async () => {
    try {
      const response = await axios.get('https://api.edamam.com/search', {
        params: {
          q: 'diabetes',
          app_id: process.env.REACT_APP_EDAMAM_APP_ID,
          app_key: process.env.REACT_APP_EDAMAM_APP_KEY,
          health: 'sugar-conscious',
        },
      });
      setRecipes(response.data.hits);
    } catch (error) {
      console.error('Error fetching recipes:', error);
    }
  };

  const fetchRedditStories = async () => {
    try {
      const tokenResponse = await axios.post(`${process.env.REACT_APP_API_URL}/reddit-access-token`);
      const accessToken = tokenResponse.data.access_token;

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/reddit-stories`, {
        params: { access_token: accessToken }
      });

      setPersonalStories(response.data);
    } catch (error) {
      console.error('Error fetching personal stories from Reddit:', error);
    }
  };

  const fetchArchivedNewsletters = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/pdf-uploads`);
      setArchivedNewsletters(response.data);
    } catch (error) {
      console.error('Error fetching archived newsletters:', error);
    }
  };

  useEffect(() => {
    fetchBlogPosts()
      .then((posts) => setBlogPosts(posts))
      .catch((error) => console.error('Error fetching blog posts:', error));

    fetchNews()
      .then((newsData) => setNews(newsData))
      .catch((error) => console.error('Error fetching news:', error));

    fetchEvents()
      .then((eventsData) => setEvents(eventsData))
      .catch((error) => console.error('Error fetching events:', error));

    fetchResearchArticles()
      .then((articlesData) => setResearchArticles(articlesData))
      .catch((error) => console.error('Error fetching research articles:', error));

    fetchPersonalStories()
      .then((storiesData) => setPersonalStories(storiesData))
      .catch((error) => console.error('Error fetching personal stories:', error));

    fetchSlideshows();
    fetchRecipes();
    fetchRedditStories();
    fetchNewsletters()
      .then((newsletters) => {
        setNewsletters(newsletters);
        console.log('Fetched newsletters:', newsletters); // Log newsletters data
      })
      .catch((error) => console.error('Error fetching newsletters:', error));

    fetchArchivedNewsletters();
  }, []);

  useEffect(() => {
    console.log('User:', user);
  }, [user]);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const openNewsletterModal = () => {
    setNewsletterModalIsOpen(true);
  };

  const closeNewsletterModal = () => {
    setNewsletterModalIsOpen(false);
  };

  const openCreateNewsletterModal = () => {
    setCreateNewsletterModalIsOpen(true);
  };

  const closeCreateNewsletterModal = () => {
    setCreateNewsletterModalIsOpen(false);
  };

  const openPreviewModal = (newsletter) => {
    if (newsletter.type === 'pdf') {
      setPdfFile(newsletter.content);
      setPreviewModalIsOpen(true);
    } else {
      setNewsletterContent(newsletter.content || []);
      setPdfFile(null);
      setPreviewModalIsOpen(true);
    }
  };

  const closePreviewModal = () => {
    setPreviewModalIsOpen(false);
  };

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!recaptchaToken) {
      alert('Please complete the CAPTCHA');
      return;
    }

    const formData = new FormData(event.target);

    alert('Form submitted successfully!');

    event.target.reset();
    setRecaptchaToken(null);
  };

  const openEditModal = (slide) => {
    setCurrentSlide(slide);
    setImageFiles(slide.images.map((image) => ({ file: null, url: image })));
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
    setCurrentSlide(null);
    setImageFiles([]);
  };

  const openNewSlideModal = () => {
    setNewImageFiles([{ file: null, url: null }]);
    setNewSlideModalIsOpen(true);
  };

  const closeNewSlideModal = () => {
    setNewSlideModalIsOpen(false);
    setNewImageFiles([]);
  };

  const handleAddImage = (setImageFiles) => {
    setImageFiles((prev) => [...prev, { file: null, url: null }]);
  };

  const handleImageChange = (event, index, setImageFiles) => {
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    setImageFiles((prev) => {
      const newImages = [...prev];
      newImages[index] = { file, url };
      return newImages;
    });
  };

  const handleRemoveImage = (index, setImageFiles) => {
    setImageFiles((prev) => prev.filter((_, i) => i !== index));
  };

  const handleNewSlideSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    newImageFiles.forEach((imageObj) => {
      if (imageObj.file) {
        formData.append('images', imageObj.file);
      }
    });

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}api/slideshows`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      await fetchSlideshows();
      closeNewSlideModal();
    } catch (error) {
      console.error('Error creating new slideshow:', error);
    }
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    imageFiles.forEach((imageObj) => {
      if (imageObj.file) {
        formData.append('images', imageObj.file);
      }
    });

    try {
      await axios.put(`${process.env.REACT_APP_API_URL}api/slideshows/${currentSlide.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      await fetchSlideshows();
      closeEditModal();
    } catch (error) {
      console.error('Error updating slideshow:', error);
    }
  };

  const openNewEventModal = () => {
    setNewEventModalIsOpen(true);
  };

  const closeNewEventModal = () => {
    setNewEventModalIsOpen(false);
  };

  const openEditEventModal = (event) => {
    setCurrentEvent(event);
    setEditEventModalIsOpen(true);
  };

  const closeEditEventModal = () => {
    setEditEventModalIsOpen(false);
    setCurrentEvent(null);
  };

  const handleNewEventSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    if (eventImage) {
      formData.append('image', eventImage);
    }

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}api/events`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      await fetchEvents();
      closeNewEventModal();
    } catch (error) {
      console.error('Error creating new event:', error);
    }
  };

  const handleEditEventSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    if (eventImage) {
      formData.append('image', eventImage);
    }

    try {
      await axios.put(`${process.env.REACT_APP_API_URL}api/events/${currentEvent.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      await fetchEvents();
      closeEditEventModal();
    } catch (error) {
      console.error('Error updating event:', error);
    }
  };

  const handleDeleteEvent = async (eventId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}api/events/${eventId}`);
      await fetchEvents();
    } catch (error) {
      console.error('Error deleting event:', error);
    }
  };

  const handleNewsletterSignUp = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const email = formData.get('email');

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      alert('Please enter a valid email address.');
      return;
    }

    try {
      await signUpNewsletter({ email });
      alert('Signed up for newsletter successfully!');
      addNotification('Successfully signed up for the newsletter!');
      closeNewsletterModal();
    } catch (error) {
      console.error('Error signing up for newsletter:', error);
    }
  };

  const handleCreateNewsletter = async (event) => {
    event.preventDefault();

    if (!user || !user.userId) {
      console.error('User is not logged in or user ID is missing');
      return;
    }

    const formData = new FormData();

    formData.append('title', event.target.title.value);
    formData.append('content', JSON.stringify(newsletterContent));
    formData.append('template', newsletterTemplate);
    formData.append('segment', selectedSegment);
    formData.append('userId', user.userId); // Corrected userId reference
    formData.append('date', new Date().toISOString());

    newsletterImages.forEach((file) => {
      formData.append('images', file);
    });

    try {
      const response = await createNewsletter(formData);
      alert('Newsletter created successfully!');
      await fetchNewsletters().then(setNewsletters);
      closeCreateNewsletterModal();
    } catch (error) {
      console.error('Error creating newsletter:', error);
    }
  };

  const handleAddSection = (type) => {
    setNewsletterContent([...newsletterContent, { type, content: '' }]);
  };

  const handleUpdateSection = (index, content) => {
    const newContent = [...newsletterContent];
    newContent[index].content = content;
    setNewsletterContent(newContent);
  };

  const moveSection = (dragIndex, hoverIndex) => {
    const dragSection = newsletterContent[dragIndex];
    setNewsletterContent(
      update(newsletterContent, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragSection],
        ],
      })
    );
  };

  const removeSection = (index) => {
    setNewsletterContent(newsletterContent.filter((_, i) => i !== index));
  };

  const renderSection = (section, index) => {
    switch (section.type) {
      case SectionType.TITLE:
        return (
          <DraggableSection id={`section-${index}`} index={index} moveSection={moveSection} removeSection={removeSection} key={index}>
            <input
              type="text"
              placeholder="Title"
              value={section.content}
              onChange={(e) => handleUpdateSection(index, e.target.value)}
              className="w-full px-4 py-2 mb-4 border rounded-md focus:ring-2 focus:ring-blue-500"
            />
          </DraggableSection>
        );
      case SectionType.TEXT:
        return (
          <DraggableSection id={`section-${index}`} index={index} moveSection={moveSection} removeSection={removeSection} key={index}>
            <ReactQuill value={section.content} onChange={(value) => handleUpdateSection(index, value)} />
          </DraggableSection>
        );
      case SectionType.IMAGE:
        return (
          <DraggableSection id={`section-${index}`} index={index} moveSection={moveSection} removeSection={removeSection} key={index}>
            <input
              type="file"
              onChange={(e) => handleUpdateSection(index, URL.createObjectURL(e.target.files[0]))}
              className="w-full px-4 py-2 mb-4 border rounded-md focus:ring-2 focus:ring-blue-500"
            />
            {section.content && <img src={section.content} alt="newsletter" className="mt-4" />}
          </DraggableSection>
        );
      case SectionType.TESTIMONIAL:
        return (
          <DraggableSection id={`section-${index}`} index={index} moveSection={moveSection} removeSection={removeSection} key={index}>
            <textarea
              placeholder="Testimonial"
              value={section.content}
              onChange={(e) => handleUpdateSection(index, e.target.value)}
              className="w-full px-4 py-2 mb-4 border rounded-md focus:ring-2 focus:ring-blue-500"
            ></textarea>
          </DraggableSection>
        );
      case SectionType.CONTACT:
        return (
          <DraggableSection id={`section-${index}`} index={index} moveSection={moveSection} removeSection={removeSection} key={index}>
            <div className="contact-info">
              <input
                type="text"
                placeholder="Address"
                value={section.content.address}
                onChange={(e) => handleUpdateSection(index, { ...section.content, address: e.target.value })}
                className="w-full px-4 py-2 mb-4 border rounded-md focus:ring-2 focus:ring-blue-500"
              />
              <input
                type="text"
                placeholder="Phone"
                value={section.content.phone}
                onChange={(e) => handleUpdateSection(index, { ...section.content, phone: e.target.value })}
                className="w-full px-4 py-2 mb-4 border rounded-md focus:ring-2 focus:ring-blue-500"
              />
              <input
                type="text"
                placeholder="Email"
                value={section.content.email}
                onChange={(e) => handleUpdateSection(index, { ...section.content, email: e.target.value })}
                className="w-full px-4 py-2 mb-4 border rounded-md focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </DraggableSection>
        );
      case SectionType.PDF:
        return (
          <DraggableSection id={`section-${index}`} index={index} moveSection={moveSection} removeSection={removeSection} key={index}>
            <a href={section.content} target="_blank" rel="noopener noreferrer" className="block text-blue-600 underline">
              View PDF
            </a>
          </DraggableSection>
        );
      default:
        return null;
    }
  };

  const renderPreview = () => {
    if (pdfFile) {
      const defaultLayoutPluginInstance = defaultLayoutPlugin();
      return (
        <div className="h-full">
          <Worker workerUrl={`https://unpkg.com/pdfjs-dist/build/pdf.worker.min.js`}>
            <div style={{ height: '750px' }}>
              <Viewer fileUrl={pdfFile} plugins={[defaultLayoutPluginInstance]} />
            </div>
          </Worker>
        </div>
      );
    } else if (newsletterContent) {
      return (
        <div className="p-8">
          {newsletterContent.map((section, index) => {
            switch (section.type) {
              case SectionType.TITLE:
                return <h1 key={index} className="text-4xl font-bold mb-4">{section.content}</h1>;
              case SectionType.TEXT:
                return <div key={index} className="prose mb-4" dangerouslySetInnerHTML={{ __html: section.content }} />;
              case SectionType.IMAGE:
                return <img key={index} src={section.content} alt="newsletter" className="mb-4" />;
              case SectionType.TESTIMONIAL:
                return <blockquote key={index} className="mb-4">{section.content}</blockquote>;
              case SectionType.CONTACT:
                return (
                  <div key={index} className="contact-info mb-4">
                    <p><strong>Address:</strong> {section.content.address}</p>
                    <p><strong>Phone:</strong> {section.content.phone}</p>
                    <p><strong>Email:</strong> {section.content.email}</p>
                  </div>
                );
              case SectionType.PDF:
                return (
                  <div key={index} className="mb-4">
                    <a href={section.content} target="_blank" rel="noopener noreferrer" className="block text-blue-600 underline">
                      View PDF
                    </a>
                  </div>
                );
              default:
                return null;
            }
          })}
        </div>
      );
    }
  };

  const handleSendNewsletter = async (newsletterId) => {
    console.log('Sending newsletter with ID:', newsletterId);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}api/newsletters/${newsletterId}/send`);
      addNotification('Newsletter sent successfully!');
    } catch (error) {
      console.error('Error sending newsletter:', error);
      addNotification('Failed to send newsletter.');
    }
  };

  const handlePdfUpload = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('pdf', file);
    formData.append('userId', user.userId); // Ensure `user.userId` is defined
    formData.append('title', file.name.replace(/\s+/g, '_'));
    formData.append('date', new Date().toISOString());

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}pdf-uploads/upload-pdf`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const { path } = response.data;

      const newSection = {
        id: `section-${newsletterContent.length}`,
        type: 'pdf',
        content: path,
      };

      setNewsletterContent([...newsletterContent, newSection]);
      await fetchNewsletters().then(setNewsletters);
    } catch (error) {
      console.error('Error uploading PDF:', error);
      alert('Failed to upload PDF.');
    }
  };

  const handleDownloadNewsletter = async (newsletter) => {
    if (newsletter.type === 'pdf') {
      const link = document.createElement('a');
      link.href = `${process.env.REACT_APP_API_URL}${newsletter.content}`;
      link.download = newsletter.title; // Adjust the file name if necessary
      link.click();
      return;
    }

    const doc = new jsPDF();

    if (newsletter.content) {
      const promises = newsletter.content.map((section, index) => {
        if (section.type === SectionType.IMAGE) {
          return new Promise((resolve) => {
            convertImgToBase64(section.content, (dataUrl) => {
              doc.addImage(dataUrl, 'JPEG', 10, 10 + index * 30, 180, 160);
              resolve();
            });
          });
        } else if (section.type === SectionType.TEXT) {
          doc.text(section.content.replace(/<\/?[^>]+(>|$)/g, ""), 10, 10 + index * 30);
          return Promise.resolve();
        } else {
          return Promise.resolve();
        }
      });

      await Promise.all(promises);
    }

    doc.save(`${newsletter.title}.pdf`);
  };

  const convertImgToBase64 = (url, callback) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.height = img.naturalHeight;
      canvas.width = img.naturalWidth;
      ctx.drawImage(img, 0, 0);
      const dataUrl = canvas.toDataURL('image/jpeg');
      callback(dataUrl);
    };
    img.src = url;
  };

  return (
    <div className="relative bg-gradient-to-r from-blue-50 to-blue-100 min-h-screen">
      <div className="text-center relative z-10">
        <header
          className="bg-fixed bg-top bg-cover bg-no-repeat"
          style={{
            backgroundImage: 'url(/Media/Images/homepagebkg2.jpg)',
            height: '75vh',
            width: '100vw',
          }}
        >
          <div className="bg-black bg-opacity-50 h-full flex flex-col justify-center items-center">
            <h1 className="text-6xl font-bold text-white drop-shadow-lg">Diabetes Self Help Group</h1>
            <p className="mt-3 text-2xl text-white drop-shadow-lg">Empowering Lives through Education and Support.</p>
          </div>
        </header>

        <section className="bg-white py-16 shadow-lg rounded-lg mx-4 md:mx-20 lg:mx-32 xl:mx-48 flex flex-col md:flex-row items-center mb-8">
          <div className="flex-shrink-0 mb-8 md:mb-0 md:mr-8 w-full md:w-1/2">
            <img
              src="Media/Images/12years.jpeg"
              alt="Self-Help Group Image"
              className="w-full h-auto rounded-lg shadow-md"
            />
          </div>
          <div className="text-left max-w-md w-full">
            <h2 className="text-3xl font-bold text-blue-900 mb-4">12 Years of Self-Help Group</h2>
            <p className="text-lg text-gray-700 mb-4">
              This year marks Self-Help Group's 12 year anniversary!
              We are so proud of how much our group has progressed, from a small group of 5, meeting twice a month, to a present group of over 100, with three time weekly meetings! 
              We look forward to what the future holds for our group and are always welcome for more members to join us, so join our group today, and be part of our future where we will always continue to empower each other!
            </p>
          </div>
        </section>

        <div className="flex flex-col items-center min-h-screen justify-center">
          <section className="bg-white py-16 shadow-lg rounded-lg mx-4 md:mx-20 lg:mx-32 xl:mx-48 flex flex-col md:flex-row items-center">
            <div className="flex-shrink-0 mb-8 md:mb-0 md:mr-8 w-full md:w-1/2 flex flex-wrap justify-center">
              <img
                src="Media/Images/activities.png"
                alt="Weekly Meetings Image"
                className="w-full md:w-auto h-auto rounded-lg shadow-md mb-4"
              />
              <img
                src="Media/Images/activities2.png"
                alt="Weekly Meetings Image"
                className="w-full md:w-auto h-auto rounded-lg shadow-md mb-4"
              />
              <img
                src="Media/Images/activities3.png"
                alt="Weekly Meetings Image"
                className="w-full md:w-auto h-auto rounded-lg shadow-md"
              />
            </div>
            <div className="text-center max-w-md w-full">
              <h2 className="text-3xl font-bold text-blue-900 mb-4">Our Current Weekly Activities</h2>
              <p className="text-lg text-gray-700">
                Take a look here at our three time weekly meetings, with Dr. Sonal Bhavsar! For more information about our activities, including time and venue, head to our activities page!
                There is always something for everyone in our activities, so come and join us!
              </p>
            </div>
          </section>
        </div>

        <section className="bg-white py-16 shadow-lg rounded-lg mx-4 md:mx-20 lg:mx-32 xl:mx-48 flex flex-col items-center">
          <div className="mb-8">
            <img
              src="Media/Images/activities4.png"
              alt="Weekly Meetings Image"
              className="w-full h-auto rounded-lg shadow-md"
            />
          </div>
          <div className="text-center">
            <h2 className="text-3xl font-bold text-blue-900 mb-4">Weekly Meetings</h2>
            <p className="text-lg text-gray-700">
              We would like to give a special mention to all our amazing volunteers who help out during our three time weekly meetings! Our volunteers provide so much support to us, including cooking and sharing healthy meals, and coming along to our Wednesday gardening sessions!
              <br /><br />
              It is all because of our volunteers that we have been able to progress our group and make so many positive changes to the lives of people who suffer with health conditions in Leicester!
              <br /><br />
              Join our group today, where you can also join our amazing group of volunteers and contribute towards making so many positive changes to the lives of those who are living with health conditions in Leicester!
            </p>
          </div>
        </section>

        <section className="bg-white p-10 shadow-lg rounded-lg mx-4 md:mx-20 lg:mx-32 xl:mx-48">
          <h2 className="text-5xl font-bold text-center mb-6 text-blue-900">Contact Us</h2>
          <p className="text-xl text-center mb-6 mx-auto max-w-2xl text-gray-700">
            Have questions or need support? Reach out to us today.
          </p>
          <div className="flex justify-center">
            <button
              onClick={openModal}
              className="bg-blue-600 text-white px-6 py-3 rounded-full shadow-lg hover:bg-blue-700 transition duration-300"
            >
              Email Us
            </button>
          </div>
          <ReactModal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            className="relative bg-white rounded-lg shadow-lg p-8 w-full max-w-lg mx-auto my-8 z-50"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-40"
          >
            <div className="flex justify-end">
              <button onClick={closeModal} className="text-gray-500 hover:text-gray-700">
                <FaTimes className="w-6 h-6" />
              </button>
            </div>
            <h2 className="text-3xl font-bold mb-4 text-center text-blue-900">Contact Form</h2>
            <form onSubmit={handleSubmit}>
              <label className="block text-left mb-2 text-gray-700">Name:</label>
              <input
                type="text"
                name="name"
                className="w-full px-4 py-2 mb-4 border rounded-md focus:ring-2 focus:ring-blue-500"
                required
              />
              <label className="block text-left mb-2 text-gray-700">Email:</label>
              <input
                type="email"
                name="email"
                className="w-full px-4 py-2 mb-4 border rounded-md focus:ring-2 focus:ring-blue-500"
                required
              />
              <label className="block text-left mb-2 text-gray-700">Message:</label>
              <textarea
                name="message"
                className="w-full px-4 py-2 mb-4 border rounded-md focus:ring-2 focus:ring-blue-500"
                required
              ></textarea>
              <div className="flex justify-center mb-4">
                <ReCAPTCHA
                  sitekey="6LcMTOMpAAAAAFo8iek86LqCDJM0Z2ElkJFLs9vc"
                  onChange={handleRecaptchaChange}
                />
              </div>
              <div className="text-right">
                <button
                  type="submit"
                  className="bg-blue-600 text-white px-6 py-3 rounded-full shadow-lg hover:bg-blue-700 transition duration-300"
                >
                  Send
                </button>
              </div>
            </form>
            <div className="mt-6">
              <h3 className="text-xl font-bold mb-2 text-gray-700">Address:</h3>
              <p className="text-gray-600"> Leicester, LE4 4BA</p>
              <h3 className="text-xl font-bold mb-2 text-gray-700">Phone:</h3>
              <p className="text-gray-600">0792165385</p>
              <h3 className="text-xl font-bold mb-2 text-gray-700">Email:</h3>
              <p className="text-gray-600">dgleicester@gmail.com</p>
              <div className="mt-4">
                <iframe
                  title="Google Maps"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3047.833434294756!2d-1.1339436844348946!3d52.64292937984148!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487761b1aef3e281%3A0x5f68b72b48c56e63!2s68%20Roman%20Rd%2C%20Leicester%20LE4%204BA%2C%20UK!5e0!3m2!1sen!2sus!4v1620206820531!5m2!1sen!2sus"
                  width="100%"
                  height="250"
                  frameBorder="0"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  aria-hidden="false"
                  tabIndex="0"
                ></iframe>
              </div>
            </div>
          </ReactModal>
        </section>

        <main className="space-y-16 py-16">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 px-8">
            {slideshows.map((slide, index) => (
              <div key={index} className="relative">
                <ImageSlider slides={[slide]} isAdmin={isAdmin} onEdit={() => openEditModal(slide)} />
              </div>
            ))}
          </div>

          {isAdmin && (
            <div className="flex justify-center mt-8">
              <button
                onClick={openNewSlideModal}
                className="bg-green-600 text-white px-4 py-2 rounded mb-4 shadow-lg hover:bg-green-700 transition duration-300"
              >
                Add New Slide
              </button>
            </div>
          )}

          <section className="bg-white p-10 shadow-lg rounded-lg mx-4 md:mx-20 lg:mx-32 xl:mx-48">
            <h2 className="text-5xl font-bold text-center mb-6 text-blue-900">Understanding Diabetes</h2>
            <div className="text-center mb-10">
              <img src="/Media/Images/diabetes2.webp" alt="Diabetes" className="mx-auto h-72 w-auto rounded-xl shadow-lg" />
            </div>
            <div className="mt-10 grid grid-cols-1 lg:grid-cols-3 gap-10">
              <div className="bg-blue-100 p-6 rounded-lg shadow-md">
                <h3 className="text-3xl font-bold mb-4 text-blue-900">Type 1 Diabetes</h3>
                <p className="text-lg text-gray-700">
                  Type 1 diabetes is a chronic condition in which the pancreas produces little or no insulin. It typically appears in adolescence.
                </p>
              </div>
              <div className="bg-red-100 p-6 rounded-lg shadow-md">
                <h3 className="text-3xl font-bold mb-4 text-red-900">Type 2 Diabetes</h3>
                <p className="text-lg text-gray-700">
                  Type 2 diabetes is a condition where the body becomes resistant to insulin, or the pancreas doesn't produce enough insulin.
                </p>
              </div>
              <div className="bg-green-100 p-6 rounded-lg shadow-md">
                <h3 className="text-3xl font-bold mb-4 text-green-900">Gestational Diabetes</h3>
                <p className="text-lg text-gray-700">
                  Gestational diabetes occurs during pregnancy and usually disappears after giving birth. It increases the risk of type 2 diabetes later in life.
                </p>
              </div>
            </div>
          </section>

          <section className="bg-gray-100 py-16 shadow-lg rounded-lg mx-4 md:mx-20 lg:mx-32 xl:mx-48">
            <h2 className="text-5xl font-bold text-center mb-12 text-blue-900">Our Services</h2>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-16 max-w-7xl mx-auto px-8">
              <a href="/consultations" className="bg-white p-8 rounded-lg shadow-lg transform transition-transform hover:scale-105 duration-300 hover:shadow-xl">
                <h3 className="text-3xl font-bold mb-4 text-blue-900">Consultations</h3>
                <p className="text-lg text-gray-700">Personalized consultations to help manage your diabetes effectively.</p>
              </a>
              <a href="/support-groups" className="bg-white p-8 rounded-lg shadow-lg transform transition-transform hover:scale-105 duration-300 hover:shadow-xl">
                <h3 className="text-3xl font-bold mb-4 text-blue-900">Support Groups</h3>
                <p className="text-lg text-gray-700">Join our support groups to connect with others and share experiences.</p>
              </a>
              <a href="/workshops" className="bg-white p-8 rounded-lg shadow-lg transform transition-transform hover:scale-105 duration-300 hover:shadow-xl">
                <h3 className="text-3xl font-bold mb-4 text-blue-900">Educational Workshops</h3>
                <p className="text-lg text-gray-700">Attend our workshops to learn more about diabetes management and care.</p>
              </a>
            </div>
          </section>

          <section className="bg-white py-16 shadow-lg rounded-lg mx-4 md:mx-20 lg:mx-32 xl:mx-48">
            <h2 className="text-5xl font-bold text-center mb-12 text-blue-900">Newsletter Sign Up</h2>
            <p className="text-xl text-center mb-6 mx-auto max-w-2xl text-gray-700">
              Sign up for our newsletter to receive the latest updates, news, and resources directly to your inbox.
            </p>
            <div className="flex justify-center">
              <button
                onClick={openNewsletterModal}
                className="bg-blue-600 text-white px-6 py-3 rounded-full shadow-lg hover:bg-blue-700 transition duration-300"
              >
                Sign Up for Newsletter
              </button>
            </div>
          </section>

          <section className="bg-white py-16 shadow-lg rounded-lg mx-4 md:mx-20 lg:mx-32 xl:mx-48">
            <h2 className="text-5xl font-bold text-center mb-12 text-blue-900">Newsletters</h2>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-16 max-w-7xl mx-auto px-8">
              {newsletters.map((newsletter) => (
                <div key={newsletter.newsletterId} className="bg-gray-100 p-8 rounded-lg shadow-lg">
                  <h3 className="text-3xl font-bold mb-4 text-blue-900">{newsletter.title}</h3>
                  <div className="flex justify-between items-center mt-4">
                    <button onClick={() => openPreviewModal(newsletter)} className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300">
                      <FaEye className="mr-2" /> View
                    </button>
                    {newsletter.type === 'pdf' ? (
                      <a
                        href={`${process.env.REACT_APP_API_URL}${newsletter.content}`}
                        download={newsletter.title}
                        className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700 transition duration-300"
                      >
                        <FaDownload className="mr-2" /> Download
                      </a>
                    ) : (
                      <button
                        onClick={() => handleDownloadNewsletter(newsletter)}
                        className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700 transition duration-300"
                      >
                        <FaDownload className="mr-2" /> Download
                      </button>
                    )}
                    {isAdmin && (
                      <button
                        onClick={() => handleSendNewsletter(newsletter.newsletterId)}
                        className="bg-purple-600 text-white px-4 py-2 rounded hover:bg-purple-700 transition duration-300"
                      >
                        <FaPaperPlane className="mr-2" /> Send
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
            {isAdmin && (
              <div className="flex justify-center mt-8">
                <button
                  onClick={() => navigate('/newsletter-editor')}
                  className="bg-green-600 text-white px-4 py-2 rounded mb-4 shadow-lg hover:bg-green-700 transition duration-300"
                >
                  Open Newsletter Editor
                </button>
              </div>
            )}
            {isAdmin && (
              <div className="flex justify-center mt-8">
                <input type="file" onChange={handlePdfUpload} accept="application/pdf" className="upload-button" />
              </div>
            )}
            <div className="flex justify-center mt-8">
              <button
                onClick={() => navigate('/ArchivedNewsletters')}
                className="bg-blue-600 text-white px-6 py-3 rounded-full shadow-lg hover:bg-blue-700 transition duration-300"
              >
                Archived Newsletters
              </button>
            </div>
          </section>

          <section className="bg-gray-100 py-16 shadow-lg rounded-lg mx-4 md:mx-20 lg:mx-32 xl:mx-48">
            <h2 className="text-5xl font-bold text-center mb-12 text-blue-900">Upcoming Events</h2>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-16 max-w-7xl mx-auto px-8">
              {events.slice(0, 3).map((event, index) => (
                <div
                  key={index}
                  className="bg-white p-8 rounded-lg shadow-lg transform transition-transform hover:scale-105 duration-300 hover:shadow-xl"
                >
                  {event.image && (
                    <img src={`${process.env.REACT_APP_API_URL}${event.image}`} alt={event.name} className="h-48 w-full object-cover rounded-t-lg mb-4" />
                  )}
                  <h3 className="text-3xl font-bold mb-4 text-blue-900">{event.name}</h3>
                  <p className="text-lg text-gray-700">{event.description}</p>
                  <p className="text-lg font-semibold mt-4 text-gray-900">{new Date(event.date).toLocaleDateString()}</p>
                  <p className="text-lg text-gray-700">{event.location}</p>
                  <p className="text-lg text-gray-700">{event.start_time} - {event.end_time}</p>
                  {isAdmin && (
                    <div className="flex space-x-2 mt-4">
                      <button
                        onClick={() => openEditEventModal(event)}
                        className="bg-blue-600 text-white px-4 py-2 rounded shadow-lg hover:bg-blue-700 transition duration-300"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleDeleteEvent(event.id)}
                        className="bg-red-600 text-white px-4 py-2 rounded shadow-lg hover:bg-red-700 transition duration-300"
                      >
                        Delete
                      </button>
                    </div>
                  )}
                </div>
              ))}
            </div>
            {isAdmin && (
              <div className="flex justify-center mt-8">
                <button
                  onClick={openNewEventModal}
                  className="bg-green-600 text-white px-4 py-2 rounded mb-4 shadow-lg hover:bg-green-700 transition duration-300"
                >
                  Add New Event
                </button>
              </div>
            )}
          </section>

          <section className="bg-white py-16 shadow-lg rounded-lg mx-4 md:mx-20 lg:mx-32 xl:mx-48">
            <h2 className="text-5xl font-bold text-center mb-12 text-blue-900">Personal Stories</h2>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-16 max-w-7xl mx-auto px-8">
              {personalStories.slice(0, 3).map((story, index) => (
                <div
                  key={index}
                  className="bg-gray-100 p-8 rounded-lg shadow-lg transform transition-transform hover:scale-105 duration-300 hover:shadow-xl"
                >
                  <h3 className="text-3xl font-bold mb-4 text-blue-900">{story.title}</h3>
                  <p className="text-lg text-gray-700">{story.content}</p>
                  <p className="text-lg font-semibold mt-4 text-gray-900">- {story.author}</p>
                </div>
              ))}
            </div>
          </section>

          <section className="bg-white py-16 shadow-lg rounded-lg mx-4 md:mx-20 lg:mx-32 xl:mx-48">
            <h2 className="text-5xl font-bold text-center mb-12 text-blue-900">Featured Recipes</h2>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-16 max-w-7xl mx-auto px-8">
              {recipes.slice(0, 3).map((recipe, index) => (
                <RecipeCard
                  key={index}
                  image={recipe.recipe.image}
                  title={recipe.recipe.label}
                  description={recipe.recipe.source}
                  url={recipe.recipe.url}
                />
              ))}
            </div>
          </section>

          <section className="bg-gray-100 py-16 shadow-lg rounded-lg mx-4 md:mx-20 lg:mx-32 xl:mx-48">
            <h2 className="text-5xl font-bold text-center mb-12 text-blue-900">Interactive Tools</h2>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 max-w-7xl mx-auto px-8">
              <BmiCalculator />
              <GlucoseTracker />
            </div>
          </section>

          <section className="bg-white py-16 shadow-lg rounded-lg mx-4 md:mx-20 lg:mx-32 xl:mx-48">
            <h2 className="text-5xl font-bold text-center mb-12 text-blue-900">Engage with Our Community</h2>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 max-w-7xl mx-auto px-8">
              <Chatbot />
              <DynamicGraph />
            </div>
          </section>
        </main>

        <footer className="bg-gray-900 text-white py-8">
          <div className="container mx-auto text-center">
            <p>&copy; 2024 Diabetes Self Help Group. All rights reserved.</p>
          </div>
        </footer>
      </div>

      <ReactModal
        isOpen={newsletterModalIsOpen}
        onRequestClose={closeNewsletterModal}
        className="relative bg-white rounded-lg shadow-lg p-8 w-full max-w-lg mx-auto my-8 z-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-40"
      >
        <div className="flex justify-end">
          <button onClick={closeNewsletterModal} className="text-gray-500 hover:text-gray-700">
            <FaTimes className="w-6 h-6" />
          </button>
        </div>
        <h2 className="text-3xl font-bold mb-4 text-center text-blue-900">Newsletter Sign Up</h2>
        <form onSubmit={handleNewsletterSignUp}>
          <label className="block text-left mb-2 text-gray-700">Email:</label>
          <input
            type="email"
            name="email"
            className="w-full px-4 py-2 mb-4 border rounded-md focus:ring-2 focus:ring-blue-500"
            required
          />
          <div className="flex justify-center mb-4">
            <ReCAPTCHA
              sitekey="6LcMTOMpAAAAAFo8iek86LqCDJM0Z2ElkJFLs9vc"
              onChange={handleRecaptchaChange}
            />
          </div>
          <div className="text-right">
            <button
              type="submit"
              className="bg-blue-600 text-white px-6 py-3 rounded-full shadow-lg hover:bg-blue-700 transition duration-300"
            >
              Sign Up
            </button>
          </div>
        </form>
      </ReactModal>

      <ReactModal
        isOpen={createNewsletterModalIsOpen}
        onRequestClose={closeCreateNewsletterModal}
        className="relative bg-white rounded-lg shadow-lg p-8 w-full max-w-4xl mx-auto my-8 z-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-40"
      >
        <div className="flex justify-end">
          <button onClick={closeCreateNewsletterModal} className="text-gray-500 hover:text-gray-700">
            <FaTimes className="w-6 h-6" />
          </button>
        </div>
        <h2 className="text-3xl font-bold mb-4 text-center text-blue-900">Create Newsletter</h2>
        <form onSubmit={handleCreateNewsletter}>
          <label className="block text-left mb-2 text-gray-700">Title:</label>
          <input
            type="text"
            name="title"
            className="w-full px-4 py-2 mb-4 border rounded-md focus:ring-2 focus:ring-blue-500"
            required
          />
          <label className="block text-left mb-2 text-gray-700">Template:</label>
          <select
            name="template"
            value={newsletterTemplate}
            onChange={(e) => setNewsletterTemplate(e.target.value)}
            className="w-full px-4 py-2 mb-4 border rounded-md focus:ring-2 focus:ring-blue-500"
          >
            <option value="default">Default</option>
            <option value="modern">Modern</option>
            <option value="classic">Classic</option>
          </select>
          <label className="block text-left mb-2 text-gray-700">Segment:</label>
          <select
            name="segment"
            value={selectedSegment}
            onChange={(e) => setSelectedSegment(e.target.value)}
            className="w-full px-4 py-2 mb-4 border rounded-md focus:ring-2 focus:ring-blue-500"
          >
            <option value="all">All</option>
            <option value="diabetes">Diabetes</option>
            <option value="pre-diabetes">Pre-Diabetes</option>
          </select>
          <div className="border-t mt-4 pt-4">
            <div className="flex justify-center mb-4">
              <button
                type="button"
                onClick={() => handleAddSection(SectionType.TITLE)}
                className="bg-blue-600 text-white px-4 py-2 rounded mr-2 shadow-lg hover:bg-blue-700 transition duration-300"
              >
                Add Title
              </button>
              <button
                type="button"
                onClick={() => handleAddSection(SectionType.TEXT)}
                className="bg-blue-600 text-white px-4 py-2 rounded mr-2 shadow-lg hover:bg-blue-700 transition duration-300"
              >
                Add Text
              </button>
              <button
                type="button"
                onClick={() => handleAddSection(SectionType.IMAGE)}
                className="bg-blue-600 text-white px-4 py-2 rounded mr-2 shadow-lg hover:bg-blue-700 transition duration-300"
              >
                Add Image
              </button>
              <button
                type="button"
                onClick={() => handleAddSection(SectionType.TESTIMONIAL)}
                className="bg-blue-600 text-white px-4 py-2 rounded mr-2 shadow-lg hover:bg-blue-700 transition duration-300"
              >
                Add Testimonial
              </button>
              <button
                type="button"
                onClick={() => handleAddSection(SectionType.CONTACT)}
                className="bg-blue-600 text-white px-4 py-2 rounded mr-2 shadow-lg hover:bg-blue-700 transition duration-300"
              >
                Add Contact Info
              </button>
              <button
                type="button"
                onClick={() => handleAddSection(SectionType.PDF)}
                className="bg-blue-600 text-white px-4 py-2 rounded shadow-lg hover:bg-blue-700 transition duration-300"
              >
                Add PDF
              </button>
            </div>
            <DndProvider backend={HTML5Backend}>
              {newsletterContent.map((section, index) => renderSection(section, index))}
            </DndProvider>
          </div>
          <label className="block text-left mb-2 text-gray-700">Upload Images:</label>
          <input
            type="file"
            multiple
            onChange={(e) => setNewsletterImages(Array.from(e.target.files))}
            className="w-full px-4 py-2 mb-4 border rounded-md focus:ring-2 focus:ring-blue-500"
          />
          <div className="text-right">
            <button
              type="submit"
              className="bg-blue-600 text-white px-6 py-3 rounded-full shadow-lg hover:bg-blue-700 transition duration-300"
            >
              Create Newsletter
            </button>
          </div>
        </form>
      </ReactModal>

      <ReactModal
        isOpen={previewModalIsOpen}
        onRequestClose={closePreviewModal}
        className="relative bg-white rounded-lg shadow-lg p-8 w-full max-w-4xl mx-auto my-8 z-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-40"
      >
        <div className="flex justify-end">
          <button onClick={closePreviewModal} className="text-gray-500 hover:text-gray-700">
            <FaTimes className="w-6 h-6" />
          </button>
        </div>
        <h2 className="text-3xl font-bold mb-4 text-center text-blue-900">Newsletter Preview</h2>
        <div className="border-t mt-4 pt-4">{renderPreview()}</div>
      </ReactModal>

      <ReactModal
        isOpen={editModalIsOpen}
        onRequestClose={closeEditModal}
        className="relative bg-white rounded-lg shadow-lg p-8 w-full max-w-4xl mx-auto my-8 z-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-40"
      >
        <div className="flex justify-end">
          <button onClick={closeEditModal} className="text-gray-500 hover:text-gray-700">
            <FaTimes className="w-6 h-6" />
          </button>
        </div>
        <h2 className="text-3xl font-bold mb-4 text-center text-blue-900">Edit Slideshow</h2>
        <form onSubmit={handleEditSubmit}>
          <label className="block text-left mb-2 text-gray-700">Title:</label>
          <input
            type="text"
            name="title"
            defaultValue={currentSlide?.title}
            className="w-full px-4 py-2 mb-4 border rounded-md focus:ring-2 focus:ring-blue-500"
            required
          />
          <label className="block text-left mb-2 text-gray-700">Description:</label>
          <textarea
            name="description"
            defaultValue={currentSlide?.description}
            className="w-full px-4 py-2 mb-4 border rounded-md focus:ring-2 focus:ring-blue-500"
            required
          ></textarea>
          <label className="block text-left mb-2 text-gray-700">Upload Images:</label>
          {imageFiles.map((imageObj, index) => (
            <div key={index} className="mb-4">
              <input
                type="file"
                onChange={(e) => handleImageChange(e, index, setImageFiles)}
                className="w-full px-4 py-2 mb-2 border rounded-md focus:ring-2 focus:ring-blue-500"
              />
              {imageObj.url && <img src={imageObj.url} alt={`slide-${index}`} className="mt-2 w-full" />}
              <button
                type="button"
                onClick={() => handleRemoveImage(index, setImageFiles)}
                className="bg-red-600 text-white px-4 py-2 rounded shadow-lg hover:bg-red-700 transition duration-300 mt-2"
              >
                Remove Image
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={() => handleAddImage(setImageFiles)}
            className="bg-green-600 text-white px-4 py-2 rounded shadow-lg hover:bg-green-700 transition duration-300 mt-2"
          >
            Add Image
          </button>
          <div className="text-right mt-6">
            <button
              type="submit"
              className="bg-blue-600 text-white px-6 py-3 rounded-full shadow-lg hover:bg-blue-700 transition duration-300"
            >
              Save Changes
            </button>
          </div>
        </form>
      </ReactModal>

      <ReactModal
        isOpen={newSlideModalIsOpen}
        onRequestClose={closeNewSlideModal}
        className="relative bg-white rounded-lg shadow-lg p-8 w-full max-w-4xl mx-auto my-8 z-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-40"
      >
        <div className="flex justify-end">
          <button onClick={closeNewSlideModal} className="text-gray-500 hover:text-gray-700">
            <FaTimes className="w-6 h-6" />
          </button>
        </div>
        <h2 className="text-3xl font-bold mb-4 text-center text-blue-900">Add New Slideshow</h2>
        <form onSubmit={handleNewSlideSubmit}>
          <label className="block text-left mb-2 text-gray-700">Title:</label>
          <input
            type="text"
            name="title"
            className="w-full px-4 py-2 mb-4 border rounded-md focus:ring-2 focus:ring-blue-500"
            required
          />
          <label className="block text-left mb-2 text-gray-700">Description:</label>
          <textarea
            name="description"
            className="w-full px-4 py-2 mb-4 border rounded-md focus:ring-2 focus:ring-blue-500"
            required
          ></textarea>
          <label className="block text-left mb-2 text-gray-700">Upload Images:</label>
          {newImageFiles.map((imageObj, index) => (
            <div key={index} className="mb-4">
              <input
                type="file"
                onChange={(e) => handleImageChange(e, index, setNewImageFiles)}
                className="w-full px-4 py-2 mb-2 border rounded-md focus:ring-2 focus:ring-blue-500"
              />
              {imageObj.url && <img src={imageObj.url} alt={`slide-${index}`} className="mt-2 w-full" />}
              <button
                type="button"
                onClick={() => handleRemoveImage(index, setNewImageFiles)}
                className="bg-red-600 text-white px-4 py-2 rounded shadow-lg hover:bg-red-700 transition duration-300 mt-2"
              >
                Remove Image
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={() => handleAddImage(setNewImageFiles)}
            className="bg-green-600 text-white px-4 py-2 rounded shadow-lg hover:bg-green-700 transition duration-300 mt-2"
          >
            Add Image
          </button>
          <div className="text-right mt-6">
            <button
              type="submit"
              className="bg-blue-600 text-white px-6 py-3 rounded-full shadow-lg hover:bg-blue-700 transition duration-300"
            >
              Add Slideshow
            </button>
          </div>
        </form>
      </ReactModal>

      <ReactModal
        isOpen={newEventModalIsOpen}
        onRequestClose={closeNewEventModal}
        className="relative bg-white rounded-lg shadow-lg p-8 w-full max-w-4xl mx-auto my-8 z-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-40"
      >
        <div className="flex justify-end">
          <button onClick={closeNewEventModal} className="text-gray-500 hover:text-gray-700">
            <FaTimes className="w-6 h-6" />
          </button>
        </div>
        <h2 className="text-3xl font-bold mb-4 text-center text-blue-900">Add New Event</h2>
        <form onSubmit={handleNewEventSubmit}>
          <label className="block text-left mb-2 text-gray-700">Event Name:</label>
          <input
            type="text"
            name="name"
            className="w-full px-4 py-2 mb-4 border rounded-md focus:ring-2 focus:ring-blue-500"
            required
          />
          <label className="block text-left mb-2 text-gray-700">Description:</label>
          <textarea
            name="description"
            className="w-full px-4 py-2 mb-4 border rounded-md focus:ring-2 focus:ring-blue-500"
            required
          ></textarea>
          <label className="block text-left mb-2 text-gray-700">Date:</label>
          <input
            type="date"
            name="date"
            className="w-full px-4 py-2 mb-4 border rounded-md focus:ring-2 focus:ring-blue-500"
            required
          />
          <label className="block text-left mb-2 text-gray-700">Start Time:</label>
          <input
            type="time"
            name="start_time"
            className="w-full px-4 py-2 mb-4 border rounded-md focus:ring-2 focus:ring-blue-500"
            required
          />
          <label className="block text-left mb-2 text-gray-700">End Time:</label>
          <input
            type="time"
            name="end_time"
            className="w-full px-4 py-2 mb-4 border rounded-md focus:ring-2 focus:ring-blue-500"
            required
          />
          <label className="block text-left mb-2 text-gray-700">Location:</label>
          <input
            type="text"
            name="location"
            className="w-full px-4 py-2 mb-4 border rounded-md focus:ring-2 focus:ring-blue-500"
            required
          />
          <label className="block text-left mb-2 text-gray-700">Upload Event Image:</label>
          <input
            type="file"
            onChange={(e) => setEventImage(e.target.files[0])}
            className="w-full px-4 py-2 mb-4 border rounded-md focus:ring-2 focus:ring-blue-500"
          />
          <div className="text-right mt-6">
            <button
              type="submit"
              className="bg-blue-600 text-white px-6 py-3 rounded-full shadow-lg hover:bg-blue-700 transition duration-300"
            >
              Add Event
            </button>
          </div>
        </form>
      </ReactModal>

      <ReactModal
        isOpen={editEventModalIsOpen}
        onRequestClose={closeEditEventModal}
        className="relative bg-white rounded-lg shadow-lg p-8 w-full max-w-4xl mx-auto my-8 z-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-40"
      >
        <div className="flex justify-end">
          <button onClick={closeEditEventModal} className="text-gray-500 hover:text-gray-700">
            <FaTimes className="w-6 h-6" />
          </button>
        </div>
        <h2 className="text-3xl font-bold mb-4 text-center text-blue-900">Edit Event</h2>
        <form onSubmit={handleEditEventSubmit}>
          <label className="block text-left mb-2 text-gray-700">Event Name:</label>
          <input
            type="text"
            name="name"
            defaultValue={currentEvent?.name}
            className="w-full px-4 py-2 mb-4 border rounded-md focus:ring-2 focus:ring-blue-500"
            required
          />
          <label className="block text-left mb-2 text-gray-700">Description:</label>
          <textarea
            name="description"
            defaultValue={currentEvent?.description}
            className="w-full px-4 py-2 mb-4 border rounded-md focus:ring-2 focus:ring-blue-500"
            required
          ></textarea>
          <label className="block text-left mb-2 text-gray-700">Date:</label>
          <input
            type="date"
            name="date"
            defaultValue={currentEvent?.date}
            className="w-full px-4 py-2 mb-4 border rounded-md focus:ring-2 focus:ring-blue-500"
            required
          />
          <label className="block text-left mb-2 text-gray-700">Start Time:</label>
          <input
            type="time"
            name="start_time"
            defaultValue={currentEvent?.start_time}
            className="w-full px-4 py-2 mb-4 border rounded-md focus:ring-2 focus:ring-blue-500"
            required
          />
          <label className="block text-left mb-2 text-gray-700">End Time:</label>
          <input
            type="time"
            name="end_time"
            defaultValue={currentEvent?.end_time}
            className="w-full px-4 py-2 mb-4 border rounded-md focus:ring-2 focus:ring-blue-500"
            required
          />
          <label className="block text-left mb-2 text-gray-700">Location:</label>
          <input
            type="text"
            name="location"
            defaultValue={currentEvent?.location}
            className="w-full px-4 py-2 mb-4 border rounded-md focus:ring-2 focus:ring-blue-500"
            required
          />
          <label className="block text-left mb-2 text-gray-700">Upload Event Image:</label>
          <input
            type="file"
            onChange={(e) => setEventImage(e.target.files[0])}
            className="w-full px-4 py-2 mb-4 border rounded-md focus:ring-2 focus:ring-blue-500"
          />
          <div className="text-right mt-6">
            <button
              type="submit"
              className="bg-blue-600 text-white px-6 py-3 rounded-full shadow-lg hover:bg-blue-700 transition duration-300"
            >
              Save Changes
            </button>
          </div>
        </form>
      </ReactModal>
    </div>
  );
};

export default HomePage;

