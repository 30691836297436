import React, { useState, useEffect } from 'react';
import { FaCaretDown, FaCaretUp, FaInfoCircle, FaArrowUp, FaCheckCircle } from 'react-icons/fa';
import { CSSTransition } from 'react-transition-group';
import 'tailwindcss/tailwind.css';
import './animations.css';

const DiabetesPage = () => {
  const [sections, setSections] = useState({
    whatIsDiabetes: true,
    symptoms: false,
    types: false,
    complications: false,
    management: false,
    leicester: false,
    global: false,
    quiz: false,
    funFacts: true,
    resources: false,
    faq: false,
  });

  const toggleSection = (section) => {
    setSections({ ...sections, [section]: !sections[section] });
  };

  const [showTopButton, setShowTopButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShowTopButton(true);
      } else {
        setShowTopButton(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <HeroSection />
      <div className="container mx-auto p-8 bg-white shadow-lg rounded-lg mt-10">
        <h1 className="text-4xl font-bold mb-8 text-center text-blue-600">Understanding Diabetes</h1>

        <Section
          title="What is Diabetes?"
          isOpen={sections.whatIsDiabetes}
          onClick={() => toggleSection('whatIsDiabetes')}
        >
          <p className="text-gray-700 leading-relaxed">
            Diabetes is a condition where the body cannot regulate blood glucose levels effectively. Insulin, produced by the pancreas, helps regulate glucose. In diabetes, either not enough insulin is produced, or the body does not use it effectively, leading to high blood sugar levels (hyperglycaemia).
          </p>
          <InteractiveInfographic />
        </Section>

        <Section
          title="Symptoms"
          isOpen={sections.symptoms}
          onClick={() => toggleSection('symptoms')}
        >
          <p className="text-gray-700 leading-relaxed">Symptoms of diabetes include:</p>
          <ul className="list-disc list-inside pl-4 text-gray-700 leading-relaxed">
            <li>Increased urination</li>
            <li>Increased thirst</li>
            <li>Hunger</li>
            <li>Weight loss</li>
            <li>Fatigue</li>
            <li>Blurred vision</li>
            <li>Infections</li>
          </ul>
        </Section>

        <Section
          title="Types of Diabetes"
          isOpen={sections.types}
          onClick={() => toggleSection('types')}
        >
          <div className="mb-4">
            <h3 className="text-xl font-semibold text-purple-500">Type 1 Diabetes</h3>
            <p className="text-gray-700 leading-relaxed">Type 1 Diabetes requires regular insulin injections and typically starts in childhood.</p>
          </div>
          <div className="mb-4">
            <h3 className="text-xl font-semibold text-purple-500">Type 2 Diabetes</h3>
            <p className="text-gray-700 leading-relaxed">Type 2 Diabetes is more common and associated with genetics and obesity, often starting in adulthood but increasingly seen in obese adolescents.</p>
          </div>
        </Section>

        <Section
          title="Long-term Complications"
          isOpen={sections.complications}
          onClick={() => toggleSection('complications')}
        >
          <p className="text-gray-700 leading-relaxed">Possible complications include:</p>
          <ul className="list-disc list-inside pl-4 text-gray-700 leading-relaxed">
            <li>Nerve damage and brain issues (e.g., dementia, stroke)</li>
            <li>Heart disease (e.g., angina, high blood pressure)</li>
            <li>Eye problems (e.g., optic nerve damage)</li>
            <li>Kidney issues (e.g., nephropathy)</li>
          </ul>
        </Section>

        <Section
          title="Self-Management"
          isOpen={sections.management}
          onClick={() => toggleSection('management')}
        >
          <p className="text-gray-700 leading-relaxed">Self-management tips include:</p>
          <ul className="list-disc list-inside pl-4 text-gray-700 leading-relaxed">
            <li>Daily exercise and light sports</li>
            <li>Healthy diet with small portions</li>
            <li>Daily foot care</li>
            <li>Regular blood sugar checks</li>
          </ul>
        </Section>

        <Section
          title="Diabetes in Leicester"
          isOpen={sections.leicester}
          onClick={() => toggleSection('leicester')}
        >
          <p className="text-gray-700 leading-relaxed">
            Leicester has a higher prevalence of diabetes compared to the national average, with 8.9% of adults affected. The rate is particularly high among older and Asian populations.
          </p>
        </Section>

        <Section
          title="Diabetes in the UK and Worldwide"
          isOpen={sections.global}
          onClick={() => toggleSection('global')}
        >
          <p className="text-gray-700 leading-relaxed">
            In the UK, around 3.7 million people have been diagnosed with diabetes. Globally, the prevalence of diabetes has increased significantly, with an estimated 422 million people affected as of 2014.
          </p>
        </Section>

        <Section
          title="Fun Facts"
          isOpen={sections.funFacts}
          onClick={() => toggleSection('funFacts')}
        >
          <FunFactsCarousel />
        </Section>

        <Section
          title="Test Your Knowledge"
          isOpen={sections.quiz}
          onClick={() => toggleSection('quiz')}
        >
          <Quiz />
        </Section>

        <Section
          title="Additional Resources"
          isOpen={sections.resources}
          onClick={() => toggleSection('resources')}
        >
          <ResourceLinks />
        </Section>

        <Section
          title="Frequently Asked Questions"
          isOpen={sections.faq}
          onClick={() => toggleSection('faq')}
        >
          <FAQ />
        </Section>

        {showTopButton && (
          <button
            onClick={scrollToTop}
            className="fixed bottom-8 right-8 bg-blue-500 text-white p-3 rounded-full shadow-lg hover:bg-blue-700 transition-colors duration-300"
          >
            <FaArrowUp />
          </button>
        )}
      </div>
    </div>
  );
};

const HeroSection = () => (
  <div className="relative bg-blue-500 text-white text-center py-20 mb-10">
    <div className="absolute inset-0 opacity-25">
      <img src={`https://diabetesselfhelpgroup.co.uk/Media/Images/diabetes.webp`} alt="Diabetes Awareness" className="w-full h-full object-cover" />
    </div>
    <div className="relative z-10">
      <h1 className="text-5xl font-bold mb-4">Diabetes Awareness & Management</h1>
      <p className="text-xl mb-8">Empowering you with knowledge and tools to manage diabetes effectively</p>
      <button className="bg-white text-blue-500 px-6 py-3 rounded-full shadow-lg font-semibold hover:bg-gray-200 transition-colors duration-300">
        Learn More
      </button>
    </div>
  </div>
);

const Section = ({ title, isOpen, onClick, children }) => (
  <section className="mb-8">
    <h2 className="text-2xl font-bold text-teal-500 mb-4 flex justify-between items-center cursor-pointer" onClick={onClick}>
      {title} {isOpen ? <FaCaretUp /> : <FaCaretDown />}
    </h2>
    <CSSTransition in={isOpen} timeout={300} classNames="accordion" unmountOnExit>
      <div>{children}</div>
    </CSSTransition>
  </section>
);

const FunFactsCarousel = () => {
  const facts = [
    "People with diabetes are twice as likely to develop heart disease.",
    "Regular exercise can significantly reduce your risk of developing Type 2 Diabetes.",
    "Healthy eating habits can help manage diabetes effectively.",
  ];

  return (
    <div className="carousel bg-gray-100 p-4 rounded-lg shadow-inner">
      {facts.map((fact, index) => (
        <div key={index} className="carousel-item flex items-center mb-4">
          <FaInfoCircle className="text-blue-500 mr-2" />
          <p className="text-gray-700 leading-relaxed">{fact}</p>
        </div>
      ))}
    </div>
  );
};

const Quiz = () => {
  const questions = [
    {
      question: "What is a common symptom of diabetes?",
      options: ["Increased thirst", "Headache", "Coughing", "Fever"],
      answer: "Increased thirst",
    },
    {
      question: "Which type of diabetes is more common?",
      options: ["Type 1", "Type 2"],
      answer: "Type 2",
    },
    {
      question: "Can diabetes lead to complications like heart disease?",
      options: ["Yes", "No"],
      answer: "Yes",
    },
  ];

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [score, setScore] = useState(0);

  const handleAnswer = (answer) => {
    if (answer === questions[currentQuestion].answer) {
      setScore(score + 1);
    }
    setSelectedAnswer(answer);
    setTimeout(() => {
      setSelectedAnswer(null);
      setCurrentQuestion(currentQuestion + 1);
    }, 1000);
  };

  return (
    <div className="quiz">
      {currentQuestion < questions.length ? (
        <div>
          <p className="text-gray-700 leading-relaxed mb-4">{questions[currentQuestion].question}</p>
          <div className="options grid grid-cols-2 gap-4">
            {questions[currentQuestion].options.map((option, index) => (
              <button
                key={index}
                className={`option py-2 px-4 rounded text-white ${selectedAnswer === option ? (option === questions[currentQuestion].answer ? 'bg-green-500' : 'bg-red-500') : 'bg-blue-500 hover:bg-blue-700'}`}
                onClick={() => handleAnswer(option)}
              >
                {option}
              </button>
            ))}
          </div>
          <ProgressIndicator current={currentQuestion + 1} total={questions.length} />
        </div>
      ) : (
        <p className="text-gray-700 leading-relaxed">Quiz complete! Your score is {score} out of {questions.length}.</p>
      )}
    </div>
  );
};

const ResourceLinks = () => {
  const resources = [
    { title: 'Diabetes UK', url: 'https://www.diabetes.org.uk/' },
    { title: 'International Diabetes Federation', url: 'https://www.idf.org/' },
    { title: 'NHS Diabetes Information', url: 'https://www.nhs.uk/conditions/diabetes/' },
  ];

  return (
    <div className="resources bg-gray-100 p-4 rounded-lg shadow-inner">
      <h3 className="text-xl font-bold text-teal-500 mb-4">Additional Resources</h3>
      <ul className="list-inside list-disc">
        {resources.map((resource, index) => (
          <li key={index} className="mb-2">
            <a href={resource.url} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
              {resource.title}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

const FAQ = () => {
  const faqs = [
    {
      question: "What is diabetes?",
      answer: "Diabetes is a chronic condition that affects how your body turns food into energy. It occurs when your body either doesn't make enough insulin or can't effectively use the insulin it makes.",
    },
    {
      question: "What are the types of diabetes?",
      answer: "The main types of diabetes are Type 1, Type 2, and gestational diabetes. Type 1 diabetes is an autoimmune condition, while Type 2 is more common and often related to lifestyle factors. Gestational diabetes occurs during pregnancy.",
    },
    {
      question: "How can diabetes be managed?",
      answer: "Diabetes management includes a combination of medication, healthy eating, regular physical activity, and monitoring blood sugar levels. Education and support from healthcare professionals are also crucial.",
    },
  ];

  return (
    <div className="faq bg-gray-100 p-4 rounded-lg shadow-inner">
      <h3 className="text-xl font-bold text-teal-500 mb-4">Frequently Asked Questions</h3>
      {faqs.map((faq, index) => (
        <div key={index} className="mb-4">
          <h4 className="font-semibold text-gray-800">{faq.question}</h4>
          <p className="text-gray-700 leading-relaxed">{faq.answer}</p>
        </div>
      ))}
    </div>
  );
};

const InteractiveInfographic = () => {
  return (
    <div className="infographic bg-gray-100 p-4 rounded-lg shadow-inner mt-4">
      <h3 className="text-xl font-bold text-teal-500 mb-4">Diabetes Statistics</h3>
      <p className="text-gray-700 leading-relaxed">Hover over the different sections to learn more about diabetes statistics and facts.</p>
      <div className="infographic-content mt-4">
        {/* Example placeholder for interactive infographic content */}
        <img src={`https://diabetesselfhelpgroup.co.uk/Media/Images/diabetes.webp`} alt="Infographic" className="w-full rounded-lg shadow-md" />
      </div>
    </div>
  );
};

const ProgressIndicator = ({ current, total }) => {
  return (
    <div className="progress-indicator mt-4">
      <p className="text-gray-700">{`Question ${current} of ${total}`}</p>
      <div className="w-full bg-gray-200 rounded-full h-2.5 mt-2">
        <div className="bg-blue-500 h-2.5 rounded-full" style={{ width: `${(current / total) * 100}%` }}></div>
      </div>
    </div>
  );
};

export default DiabetesPage;
