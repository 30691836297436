import React, { useState, useEffect, useRef } from 'react';
import ReactModal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import { AiOutlineRobot, AiOutlineUser } from 'react-icons/ai';
import { MdEmojiEmotions } from 'react-icons/md';
import ReactMarkdown from 'react-markdown';

ReactModal.setAppElement('#root'); // Ensure ReactModal works correctly with accessibility

const Chatbot = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [scrollButtonVisible, setScrollButtonVisible] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);

  const emojis = ['😀', '😁', '😂', '🤣', '😃', '😄', '😅', '😆', '😉', '😊'];

  useEffect(() => {
    if (isOpen) {
      setMessages([
        { sender: 'bot', text: 'Hello! How can I assist you today? You can ask about diabetes or navigating the website.', timestamp: new Date() }
      ]);
    }
  }, [isOpen]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (chatContainerRef.current) {
        chatContainerRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const handleScroll = () => {
    if (chatContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
      setScrollButtonVisible(scrollTop + clientHeight < scrollHeight - 100);
    }
  };

  const handleSend = async (message = input) => {
    if (message.trim()) {
      const userMessage = { sender: 'user', text: message, timestamp: new Date() };
      setMessages((prevMessages) => [...prevMessages, userMessage]);
      setInput('');

      setIsTyping(true);

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/chatbot`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ message }),
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const botMessage = await response.json();
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: 'bot', text: botMessage.text, timestamp: new Date() },
        ]);
      } catch (error) {
        console.error('Error fetching bot response:', error);
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: 'bot', text: 'Sorry, I didn\'t understand that. Can you try rephrasing?', timestamp: new Date() },
        ]);
      } finally {
        setIsTyping(false);
      }
    }
  };

  const QuickReplies = ({ options, onReply }) => (
    <div className="flex flex-wrap space-x-2 mt-2">
      {options.map((option, index) => (
        <button
          key={index}
          onClick={() => onReply(option)}
          className="bg-blue-100 text-blue-800 px-4 py-2 rounded-full shadow hover:bg-blue-200 transition duration-200"
        >
          {option}
        </button>
      ))}
    </div>
  );

  const handleEmojiClick = (emoji) => {
    setInput((prevInput) => prevInput + emoji);
    setShowEmojiPicker(false);
  };

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="fixed bottom-8 right-8 bg-blue-600 text-white p-4 rounded-full shadow-lg hover:bg-blue-700 transition duration-300 transform hover:-translate-y-1 hover:scale-110"
      >
        Chat
      </button>

      <ReactModal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        className="fixed bottom-16 right-16 bg-white rounded-lg shadow-lg p-4 w-96 h-1/1 z-50 overflow-hidden" // Increased width and height
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-40"
      >
        <div className="flex justify-between items-center mb-4 border-b pb-2">
          <h3 className="text-2xl font-semibold">Chat with Us</h3>
          <button onClick={() => setIsOpen(false)} className="text-gray-500 hover:text-gray-700 transition duration-200">
            <FaTimes className="w-6 h-6" />
          </button>
        </div>
        <div className="flex-1 overflow-y-auto h-5/6 no-scrollbar" ref={chatContainerRef}> {/* Adjusted height */}
          <div className="space-y-4">
            {messages.map((message, index) => (
              <div key={index} className={`flex ${message.sender === 'user' ? 'justify-end' : 'justify-start'}`}>
                <div className={`max-w-xs p-3 rounded-lg shadow ${message.sender === 'user' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-900'}`}>
                  <ReactMarkdown>{message.text}</ReactMarkdown>
                  <div className="flex justify-end">
                    {message.sender === 'user' ? (
                      <AiOutlineUser className="ml-2 w-4 h-4 text-gray-400" />
                    ) : (
                      <AiOutlineRobot className="ml-2 w-4 h-4 text-gray-400" />
                    )}
                  </div>
                </div>
              </div>
            ))}
            {isTyping && (
              <div className="flex justify-start">
                <div className="max-w-xs p-3 rounded-lg shadow bg-gray-200 text-gray-900">
                  <div className="flex items-center">
                    <span className="mr-2">...</span>
                    <AiOutlineRobot className="w-4 h-4 text-gray-400" />
                  </div>
                </div>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>
          <QuickReplies options={["Billing", "Service", "Support"]} onReply={(option) => handleSend(option)} />
          <div className="mt-4 flex items-center">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              className="flex-1 border p-2 rounded-full mr-2 focus:outline-none focus:ring-2 focus:ring-blue-600 transition duration-200"
              placeholder="Type your message..."
            />
            <button onClick={() => setShowEmojiPicker(!showEmojiPicker)} className="text-gray-400 hover:text-gray-600 focus:outline-none">
              <MdEmojiEmotions className="w-6 h-6" />
            </button>
            <button onClick={() => handleSend()} className="bg-blue-600 text-white px-6 py-2 rounded-full shadow-lg hover:bg-blue-700 transition duration-300 transform hover:-translate-y-1 hover:scale-110">
              Send
            </button>
          </div>
          {showEmojiPicker && (
            <div className="absolute bottom-24 right-4 bg-white p-2 rounded-lg shadow-lg">
              <div className="grid grid-cols-5 gap-2">
                {emojis.map((emoji, index) => (
                  <button
                    key={index}
                    onClick={() => handleEmojiClick(emoji)}
                    className="text-xl"
                  >
                    {emoji}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
      </ReactModal>
    </>
  );
};

export default Chatbot;
