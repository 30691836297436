// src/components/BmiCalculator.js
import React, { useState } from 'react';

const BmiCalculator = () => {
  const [weight, setWeight] = useState('');
  const [height, setHeight] = useState('');
  const [bmi, setBmi] = useState(null);
  const [message, setMessage] = useState('');

  const calculateBmi = (e) => {
    e.preventDefault();
    if (weight && height) {
      const heightInMeters = height / 100;
      const bmiValue = (weight / (heightInMeters * heightInMeters)).toFixed(2);
      setBmi(bmiValue);
      setMessage(getBmiMessage(bmiValue));
    }
  };

  const getBmiMessage = (bmi) => {
    if (bmi < 18.5) return 'Underweight';
    if (bmi >= 18.5 && bmi < 24.9) return 'Normal weight';
    if (bmi >= 25 && bmi < 29.9) return 'Overweight';
    return 'Obesity';
  };

  return (
    <div className="bg-white p-8 rounded-lg shadow-lg">
      <h3 className="text-3xl font-bold mb-4">BMI Calculator</h3>
      <form onSubmit={calculateBmi}>
        <div className="mb-4">
          <label className="block text-left mb-2">Weight (kg):</label>
          <input
            type="number"
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
            className="w-full px-4 py-2 border rounded-md"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-left mb-2">Height (cm):</label>
          <input
            type="number"
            value={height}
            onChange={(e) => setHeight(e.target.value)}
            className="w-full px-4 py-2 border rounded-md"
            required
          />
        </div>
        <button type="submit" className="bg-blue-600 text-white px-6 py-3 rounded-full shadow-lg hover:bg-blue-700 transition duration-300">
          Calculate BMI
        </button>
      </form>
      {bmi && (
        <div className="mt-4">
          <p className="text-lg">Your BMI: {bmi}</p>
          <p className="text-lg">{message}</p>
        </div>
      )}
    </div>
  );
};

export default BmiCalculator;
