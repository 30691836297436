import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { useUser } from '../components/contexts/UserContext';
import { useCart } from '../components/contexts/CartContext';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaSpinner } from 'react-icons/fa';
import { AiOutlineCheckCircle } from 'react-icons/ai';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5003';
const API_KEY = 'aba91303d69b451fb666528edd1fa289';

const StripeCheckout = ({ total }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { user, token } = useUser();
  const { cart, fetchCart, removeItemFromCart } = useCart();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [guestCheckout, setGuestCheckout] = useState(!user);
  const [shipping, setShipping] = useState({
    name: '',
    address: '',
    city: '',
    county: '',
    postcode: '',
    country: 'GB',
  });
  const [billing, setBilling] = useState({
    name: '',
    address: '',
    city: '',
    county: '',
    postcode: '',
    country: 'GB',
  });
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [errors, setErrors] = useState({});
  const [promoCode, setPromoCode] = useState('');
  const [discount, setDiscount] = useState(0);
  const [orderNotes, setOrderNotes] = useState('');
  const [shippingMethod, setShippingMethod] = useState('standard');
  const [loyaltyPoints, setLoyaltyPoints] = useState(0);
  const [redeemPoints, setRedeemPoints] = useState(false);
  const [giftOption, setGiftOption] = useState(false);
  const [giftMessage, setGiftMessage] = useState('');
  const navigate = useNavigate();

  const productItems = cart.filter(item => item.productId !== null);

  useEffect(() => {
    if (user && !guestCheckout) {
      axios.get(`${API_URL}/users/${user.userId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => {
        const userData = response.data;
        setEmail(userData.email || '');
        setName(userData.username || '');
        setShipping({
          name: userData.username || '',
          address: userData.shippingAddress || '',
          city: userData.shippingCity || '',
          county: userData.shippingCounty || '',
          postcode: userData.shippingPostcode || '',
          country: 'GB',
        });
        setBilling({
          name: userData.username || '',
          address: userData.billingAddress || '',
          city: userData.billingCity || '',
          county: userData.billingCounty || '',
          postcode: userData.billingPostcode || '',
          country: 'GB',
        });
        setLoyaltyPoints(userData.loyaltyPoints || 0);
      })
      .catch(error => {
        console.error('Failed to fetch user data:', error);
      });
    }
  }, [user, token, guestCheckout]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      toast.error('Stripe has not loaded yet.');
      setLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name,
        email,
        address: {
          line1: billing.address,
          city: billing.city,
          state: billing.county,
          postal_code: billing.postcode,
          country: billing.country,
        },
      },
    });

    if (error) {
      console.error(error);
      toast.error('Payment failed');
      setLoading(false);
      return;
    }

    const { id } = paymentMethod;

    const activitiesItems = cart.filter(item => item.activitiesId !== null).map(item => ({
      userId: user?.userId,
      activitiesId: item.activitiesId,
      quantity: item.quantity,
    }));

    try {
      const response = await axios.post(`${API_URL}/api/checkout`, {
        paymentMethodId: id,
        amount: (total - discount) * 100,
        email,
        shipping: {
          name: shipping.name,
          address: {
            line1: shipping.address,
            city: shipping.city,
            state: shipping.county,
            postal_code: shipping.postcode,
            country: shipping.country,
          },
        },
        billing: {
          name: billing.name,
          address: {
            line1: billing.address,
            city: billing.city,
            state: billing.county,
            postal_code: billing.postcode,
            country: billing.country,
          },
        },
        userId: user?.userId,
        products: productItems,
        activities: activitiesItems,
        shippingMethod,
        orderNotes,
        redeemPoints,
        giftOption,
        giftMessage,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.data.success) {
        console.log('Payment successful');
        toast.success('Payment successful!');

        await axios.post(`${API_URL}/api/send-confirmation-email`, {
          email,
          orderId: response.data.orderId,
          bookingTime: response.data.bookingTime,
          total: total,
        }, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        for (const item of cart) {
          await removeItemFromCart(item.id);
        }

        await fetchCart(); // Refresh the cart

        if (activitiesItems.length > 0) {
          navigate('/booking', { state: { orderId: response.data.orderId, bookingTime: response.data.bookingTime } });
        } else {
          navigate('/orders', { state: { orderId: response.data.orderId, items: productItems, total: total } });
        }
      } else {
        console.error('Payment failed');
        toast.error('Payment failed');
      }
    } catch (error) {
      console.error('Error processing payment:', error);
      toast.error('Error processing payment');
    } finally {
      setLoading(false);
    }
  };

  const validateStep = () => {
    let newErrors = {};
    if (step === 1) {
      if (!email) newErrors.email = 'Email is required';
      if (!name) newErrors.name = 'Name is required';
    } else if (step === 2) {
      if (!shipping.name) newErrors.shippingName = 'Name is required';
      if (!shipping.address) newErrors.shippingAddress = 'Address is required';
      if (!shipping.city) newErrors.shippingCity = 'City is required';
      if (!shipping.county) newErrors.shippingCounty = 'County is required';
      if (!shipping.postcode) newErrors.shippingPostcode = 'Postcode is required';
    } else if (step === 3) {
      if (!billing.name) newErrors.billingName = 'Name is required';
      if (!billing.address) newErrors.billingAddress = 'Address is required';
      if (!billing.city) newErrors.billingCity = 'City is required';
      if (!billing.county) newErrors.billingCounty = 'County is required';
      if (!billing.postcode) newErrors.billingPostcode = 'Postcode is required';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    if (validateStep()) {
      setStep(step + 1);
    } else {
      toast.error('Please fill in all required fields.');
    }
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const applyPromoCode = async () => {
    try {
      const response = await axios.post(`${API_URL}/api/apply-promo`, { code: promoCode });
      if (response.data.success) {
        setDiscount(response.data.discount);
        toast.success('Promo code applied!');
      } else {
        toast.error('Invalid promo code');
      }
    } catch (error) {
      console.error('Error applying promo code:', error);
      toast.error('Error applying promo code');
    }
  };

  const handleCardChange = (event) => {
    if (event.error) {
      setErrors((prev) => ({ ...prev, card: event.error.message }));
    } else {
      setErrors((prev) => ({ ...prev, card: '' }));
    }
  };

  const renderOrderSummary = () => (
    <div className="mb-4 p-4 bg-gray-100 rounded shadow">
      <h3 className="text-lg font-bold mb-2">Order Summary</h3>
      <ul className="mb-4">
        {cart.map((item) => (
          <li key={item.id} className="flex justify-between">
            <span>{item.name} (x{item.quantity})</span>
            <span>£{item.price * item.quantity}</span>
          </li>
        ))}
      </ul>
      {discount > 0 && (
        <div className="flex justify-between mb-2 text-green-500">
          <span>Discount:</span>
          <span>-£{discount}</span>
        </div>
      )}
      
      <div className="flex justify-between font-bold">
        <span>Total:</span>
        <span>£{total - discount - (redeemPoints ? loyaltyPoints : 0)}</span>
      </div>
      <div className="mt-4">
        <input
          type="text"
          value={promoCode}
          onChange={(e) => setPromoCode(e.target.value)}
          placeholder="Promo code"
          className="w-full p-2 border border-gray-300 rounded mb-2"
        />
        <button
          type="button"
          onClick={applyPromoCode}
          className="w-full py-2 px-4 bg-green-500 hover:bg-green-700 text-white font-bold rounded"
        >
          Apply Promo Code
        </button>
      </div>
    </div>
  );

  return (
    <form onSubmit={handleSubmit} className="max-w-lg mx-auto p-4 bg-white shadow-lg rounded-lg">
      <h2 className="text-2xl font-bold mb-4 text-center">Checkout</h2>

      {!user && (
        <div className="flex items-center justify-between mb-4">
          <button type="button" onClick={() => setGuestCheckout(!guestCheckout)} className="text-sm text-blue-500">
            {guestCheckout ? 'Sign in for faster checkout' : 'Checkout as guest'}
          </button>
          <p className="text-sm">Step {step} of 3</p>
        </div>
      )}

      {step === 1 && (
        <>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
            {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
          </div>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium text-gray-700">Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
            {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
          </div>
          {renderOrderSummary()}
          <button type="button" onClick={handleNext} className="w-full py-2 px-4 bg-blue-500 hover:bg-blue-700 text-white font-bold rounded">
            Next
          </button>
        </>
      )}

      {step === 2 && (
        <>
          <h3 className="text-xl font-bold mb-2">Shipping Address</h3>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium text-gray-700">Name</label>
            <input
              type="text"
              value={shipping.name}
              onChange={(e) => setShipping({ ...shipping, name: e.target.value })}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
            {errors.shippingName && <p className="text-red-500 text-sm">{errors.shippingName}</p>}
          </div>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium text-gray-700">Address</label>
            <AddressAutocomplete
              value={shipping.address}
              onChange={(value) => setShipping({ ...shipping, address: value })}
              onSelect={(formattedAddress, placeId) => {
                axios.get(`https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(formattedAddress)}&key=${API_KEY}`)
                  .then((response) => {
                    const result = response.data.results[0];
                    const addressComponents = result.components;
                    setShipping({
                      ...shipping,
                      address: formattedAddress,
                      city: addressComponents.city || addressComponents.town || '',
                      county: addressComponents.state || '',
                      postcode: addressComponents.postcode || '',
                      country: 'GB',
                    });
                  })
                  .catch((error) => {
                    console.error('Error fetching address details:', error);
                  });
              }}
            />
            {errors.shippingAddress && <p className="text-red-500 text-sm">{errors.shippingAddress}</p>}
          </div>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium text-gray-700">City</label>
            <input
              type="text"
              value={shipping.city}
              onChange={(e) => setShipping({ ...shipping, city: e.target.value })}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
            {errors.shippingCity && <p className="text-red-500 text-sm">{errors.shippingCity}</p>}
          </div>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium text-gray-700">County</label>
            <input
              type="text"
              value={shipping.county}
              onChange={(e) => setShipping({ ...shipping, county: e.target.value })}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
            {errors.shippingCounty && <p className="text-red-500 text-sm">{errors.shippingCounty}</p>}
          </div>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium text-gray-700">Postcode</label>
            <input
              type="text"
              value={shipping.postcode}
              onChange={(e) => setShipping({ ...shipping, postcode: e.target.value })}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
            {errors.shippingPostcode && <p className="text-red-500 text-sm">{errors.shippingPostcode}</p>}
          </div>
          {productItems.length > 0 && (
            <div className="mb-4">
              <label className="block mb-2 text-sm font-medium text-gray-700">Shipping Method</label>
              <select
                value={shippingMethod}
                onChange={(e) => setShippingMethod(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded"
              >
                <option value="standard">Standard - £5.00</option>
                <option value="express">Express - £10.00</option>
              </select>
            </div>
          )}
          <button type="button" onClick={handleBack} className="w-full py-2 px-4 bg-gray-500 hover:bg-gray-700 text-white font-bold rounded mb-2">
            Back
          </button>
          <button type="button" onClick={handleNext} className="w-full py-2 px-4 bg-blue-500 hover:bg-blue-700 text-white font-bold rounded">
            Next
          </button>
        </>
      )}

      {step === 3 && (
        <>
          <h3 className="text-xl font-bold mb-2">Billing Address</h3>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium text-gray-700">Name</label>
            <input
              type="text"
              value={billing.name}
              onChange={(e) => setBilling({ ...billing, name: e.target.value })}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
            {errors.billingName && <p className="text-red-500 text-sm">{errors.billingName}</p>}
          </div>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium text-gray-700">Address</label>
            <AddressAutocomplete
              value={billing.address}
              onChange={(value) => setBilling({ ...billing, address: value })}
              onSelect={(formattedAddress, placeId) => {
                axios.get(`https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(formattedAddress)}&key=${API_KEY}`)
                  .then((response) => {
                    const result = response.data.results[0];
                    const addressComponents = result.components;
                    setBilling({
                      ...billing,
                      address: formattedAddress,
                      city: addressComponents.city || addressComponents.town || '',
                      county: addressComponents.state || '',
                      postcode: addressComponents.postcode || '',
                      country: 'GB',
                    });
                  })
                  .catch((error) => {
                    console.error('Error fetching address details:', error);
                  });
              }}
            />
            {errors.billingAddress && <p className="text-red-500 text-sm">{errors.billingAddress}</p>}
          </div>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium text-gray-700">City</label>
            <input
              type="text"
              value={billing.city}
              onChange={(e) => setBilling({ ...billing, city: e.target.value })}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
            {errors.billingCity && <p className="text-red-500 text-sm">{errors.billingCity}</p>}
          </div>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium text-gray-700">County</label>
            <input
              type="text"
              value={billing.county}
              onChange={(e) => setBilling({ ...billing, county: e.target.value })}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
            {errors.billingCounty && <p className="text-red-500 text-sm">{errors.billingCounty}</p>}
          </div>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium text-gray-700">Postcode</label>
            <input
              type="text"
              value={billing.postcode}
              onChange={(e) => setBilling({ ...billing, postcode: e.target.value })}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
            {errors.billingPostcode && <p className="text-red-500 text-sm">{errors.billingPostcode}</p>}
          </div>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium text-gray-700">Card Details</label>
            <div className="p-2 border border-gray-300 rounded">
              <CardElement onChange={handleCardChange} options={{style: {base: {fontSize: '16px', color: '#32325d', '::placeholder': {color: '#a0aec0'}}}}} />
            </div>
            {errors.card && <p className="text-red-500 text-sm">{errors.card}</p>}
          </div>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium text-gray-700">Order Notes</label>
            <textarea
              value={orderNotes}
              onChange={(e) => setOrderNotes(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
              placeholder="Any special instructions?"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium text-gray-700">Gift Options</label>
            <div className="flex items-center">
              <input
                type="checkbox"
                checked={giftOption}
                onChange={() => setGiftOption(!giftOption)}
                className="mr-2"
              />
              <span className="text-sm">This is a gift</span>
            </div>
            {giftOption && (
              <textarea
                value={giftMessage}
                onChange={(e) => setGiftMessage(e.target.value)}
                className="w-full mt-2 p-2 border border-gray-300 rounded"
                placeholder="Gift message"
              />
            )}
          </div>
          
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium text-gray-700">Trust & Security</label>
            <div className="flex items-center space-x-4">
              <AiOutlineCheckCircle className="text-green-500" />
              <span className="text-sm">Secure checkout</span>
              <AiOutlineCheckCircle className="text-green-500" />
              <span className="text-sm">100% money-back guarantee</span>
              <AiOutlineCheckCircle className="text-green-500" />
              <span className="text-sm">24/7 customer support</span>
            </div>
          </div>
          <button type="button" onClick={handleBack} className="w-full py-2 px-4 bg-gray-500 hover:bg-gray-700 text-white font-bold rounded mb-2">
            Back
          </button>
          <button
            type="submit"
            disabled={!stripe || loading}
            className={`w-full py-2 px-4 ${loading ? 'bg-gray-400' : 'bg-blue-500 hover:bg-blue-700'} text-white font-bold rounded flex justify-center items-center`}
          >
            {loading ? <FaSpinner className="animate-spin mr-2" /> : `Pay £${total - discount - (redeemPoints ? loyaltyPoints : 0)}`}
          </button>
        </>
      )}
    </form>
  );
};

const AddressAutocomplete = ({ value, onChange, onSelect }) => {
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    if (value) {
      axios.get(`https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(value)}&key=${API_KEY}&countrycode=GB`)
        .then((response) => {
          const results = response.data.results;
          setSuggestions(results.map(result => ({
            formatted: result.formatted,
            place_id: result.annotations.place_id,
          })));
        })
        .catch((error) => {
          console.error('Error fetching address suggestions:', error);
        });
    } else {
      setSuggestions([]);
    }
  }, [value]);

  const handleSelect = (formattedAddress, placeId) => {
    onSelect(formattedAddress, placeId);
    setSuggestions([]); // Clear suggestions after selection
  };

  return (
    <div className="relative">
      <input
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="w-full p-2 border border-gray-300 rounded"
        style={{ background: '#fff', color: '#000' }}
      />
      {suggestions.length > 0 && (
        <ul className="absolute left-0 right-0 bg-white border border-gray-300 rounded mt-1 z-10">
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              className="p-2 cursor-pointer hover:bg-gray-200"
              onClick={() => handleSelect(suggestion.formatted, suggestion.place_id)}
            >
              {suggestion.formatted}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default StripeCheckout;
