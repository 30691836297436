import React, { useState } from 'react';
import axios from 'axios';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FaTrash, FaGripVertical, FaUndo, FaRedo, FaCog, FaDesktop, FaTabletAlt, FaMobileAlt, FaTwitter, FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import WidgetMenu from './WidgetMenu';
import LivePreview from './LivePreview';
import WidgetSettings from './WidgetSettings';

const SectionType = {
  TITLE: 'title',
  TEXT: 'text',
  IMAGE: 'image',
  VIDEO: 'video',
  BUTTON: 'button',
  ICON: 'icon',
  COLUMN: 'column',
  SOCIAL: 'social',
  FORM: 'form',
  COUNTDOWN: 'countdown',
  MAP: 'map',
};

const initialSections = [
  { id: 'section-0', type: SectionType.TITLE, content: 'Sample Title' },
  { id: 'section-1', type: SectionType.TEXT, content: '<p>Sample text content</p>' },
];

const NewsletterEditor = () => {
  const [sections, setSections] = useState(initialSections);
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [currentSection, setCurrentSection] = useState(null);
  const [undoStack, setUndoStack] = useState([]);
  const [redoStack, setRedoStack] = useState([]);
  const [deviceView, setDeviceView] = useState('desktop');
  const navigate = useNavigate(); // Use navigate hook for redirection

  const handleAddSection = (type) => {
    const newSection = { id: `section-${sections.length}`, type, content: '' };
    setUndoStack([...undoStack, sections]);
    setRedoStack([]);
    setSections([...sections, newSection]);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(sections);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setUndoStack([...undoStack, sections]);
    setRedoStack([]);
    setSections(items);
  };

  const handleUpdateSection = (id, updatedSection) => {
    setUndoStack([...undoStack, sections]);
    setRedoStack([]);
    setSections((prev) => prev.map((sec) => (sec.id === id ? updatedSection : sec)));
  };

  const handleRemoveSection = (id) => {
    const sectionToRemove = sections.find(section => section.id === id);
    if (sectionToRemove.type === SectionType.IMAGE) {
      // If it's an image section, delete the temporary image
      const imagePath = sectionToRemove.content;
      axios.post(`${process.env.REACT_APP_API_URL}/api/newsletters/delete-temp-image`, { path: imagePath })
        .then(() => {
          console.log('Temporary image deleted');
        })
        .catch(error => {
          console.error('Error deleting temporary image:', error);
        });
    }

    setUndoStack([...undoStack, sections]);
    setRedoStack([]);
    setSections(sections.filter((section) => section.id !== id));
  };

  const handleUndo = () => {
    if (undoStack.length === 0) return;

    const previousState = undoStack.pop();
    setRedoStack([sections, ...redoStack]);
    setSections(previousState);
  };

  const handleRedo = () => {
    if (redoStack.length === 0) return;

    const nextState = redoStack.shift();
    setUndoStack([...undoStack, sections]);
    setSections(nextState);
  };

  const openTemplateModal = () => setShowTemplateModal(true);
  const closeTemplateModal = () => setShowTemplateModal(false);

  const openSettingsModal = (section) => {
    setCurrentSection(section);
    setShowSettingsModal(true);
  };
  const closeSettingsModal = () => setShowSettingsModal(false);

  const templates = [
    {
      name: 'Template 1',
      sections: [
        { id: 'section-0', type: SectionType.TITLE, content: 'Template 1 Title' },
        { id: 'section-1', type: SectionType.TEXT, content: '<p>Template 1 text content</p>' },
      ],
    },
    {
      name: 'Template 2',
      sections: [
        { id: 'section-0', type: SectionType.TITLE, content: 'Template 2 Title' },
        { id: 'section-1', type: SectionType.TEXT, content: '<p>Template 2 text content</p>' },
      ],
    },
  ];

  const loadTemplate = (template) => {
    setUndoStack([...undoStack, sections]);
    setRedoStack([]);
    setSections(template.sections);
    closeTemplateModal();
  };

  const handleSave = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/newsletters`, {
        userId: 1, // Replace with the actual user ID if needed
        title: "Your Newsletter Title",
        content: JSON.stringify(sections), // Serialize the sections array to a JSON string
        date: new Date().toISOString(), // Replace with the actual date if needed
      });
      console.log('Newsletter saved:', response.data);
      alert('Newsletter saved successfully!');
      navigate('/'); // Redirect to home page after saving
    } catch (error) {
      console.error('Error saving newsletter:', error);
      alert('Failed to save newsletter.');
    }
  };

  const handlePdfUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('pdf', file);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/newsletters/upload-pdf`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const extractedData = response.data.data;

      // Convert extractedData into sections
      const newSections = extractedData.flatMap((data, index) => {
        const sectionsArray = [];
        if (data.textContent) {
          sectionsArray.push({
            id: `section-${sections.length + index}`,
            type: SectionType.TEXT,
            content: data.textContent
          });
        }
        if (data.images.length) {
          data.images.forEach((image, imgIndex) => {
            sectionsArray.push({
              id: `section-${sections.length + index}-${imgIndex}`,
              type: SectionType.IMAGE,
              content: `${process.env.REACT_APP_API_URL}${image.path}`
            });
          });
        }
        return sectionsArray;
      });

      setSections([...sections, ...newSections]);
    } catch (error) {
      console.error('Error uploading PDF:', error);
      alert('Failed to upload PDF.');
    }
  };

  return (
    <div className="newsletter-editor flex">
      <div className="widget-menu flex-shrink-0 w-1/4 h-screen overflow-y-auto">
        <WidgetMenu onAddSection={handleAddSection} />
        <input type="file" onChange={handlePdfUpload} accept="application/pdf" className="upload-button mt-4" />
      </div>
      <div className="editor-content flex-1 p-4">
        <div className="editor-toolbar flex justify-between mb-4">
          <div className="device-switcher flex space-x-2">
            <button onClick={() => setDeviceView('desktop')} className={`p-2 rounded ${deviceView === 'desktop' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}><FaDesktop /></button>
            <button onClick={() => setDeviceView('tablet')} className={`p-2 rounded ${deviceView === 'tablet' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}><FaTabletAlt /></button>
            <button onClick={() => setDeviceView('mobile')} className={`p-2 rounded ${deviceView === 'mobile' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}><FaMobileAlt /></button>
          </div>
          <div className="editor-actions flex space-x-2">
            <button onClick={handleUndo} className="p-2 bg-gray-200 rounded"><FaUndo /></button>
            <button onClick={handleRedo} className="p-2 bg-gray-200 rounded"><FaRedo /></button>
            <button onClick={openTemplateModal} className="p-2 bg-gray-200 rounded">Templates</button>
            <button onClick={handleSave} className="p-2 bg-blue-500 text-white rounded">Save</button>
          </div>
        </div>
        <div className="editor-body flex">
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="sections">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef} className="editor-sections flex-1 space-y-4 p-4 bg-gray-100 rounded">
                  {sections.map((section, index) => (
                    <Section
                      key={section.id}
                      section={section}
                      index={index}
                      handleUpdateSection={handleUpdateSection}
                      handleRemoveSection={handleRemoveSection}
                      openSettingsModal={openSettingsModal}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <LivePreview sections={sections} deviceView={deviceView} />
        </div>
        <Modal
          isOpen={showTemplateModal}
          onRequestClose={closeTemplateModal}
          contentLabel="Select Template"
          className="modal"
          overlayClassName="modal-overlay"
        >
          <h2 className="text-2xl font-bold mb-4">Select Template</h2>
          <div className="space-y-4">
            {templates.map((template, index) => (
              <button
                key={index}
                onClick={() => loadTemplate(template)}
                className="bg-blue-500 text-white px-4 py-2 rounded w-full text-left"
              >
                {template.name}
              </button>
            ))}
          </div>
          <button onClick={closeTemplateModal} className="mt-4 text-red-600">
            Close
          </button>
        </Modal>
        {showSettingsModal && currentSection && (
          <Modal
            isOpen={showSettingsModal}
            onRequestClose={closeSettingsModal}
            contentLabel="Section Settings"
            className="modal"
            overlayClassName="modal-overlay"
          >
            <WidgetSettings
              section={currentSection}
              onUpdateSection={(updatedSection) => {
                handleUpdateSection(currentSection.id, updatedSection);
                closeSettingsModal();
              }}
            />
            <button onClick={closeSettingsModal} className="mt-4 text-red-600">
              Close
            </button>
          </Modal>
        )}
      </div>
    </div>
  );
};

const Section = ({ section, index, handleUpdateSection, handleRemoveSection, openSettingsModal }) => {
  const renderSectionContent = () => {
    switch (section.type) {
      case SectionType.TITLE:
        return (
          <input
            type="text"
            value={section.content}
            onChange={(e) => handleUpdateSection(section.id, { ...section, content: e.target.value })}
            placeholder="Title"
            className="w-full p-2 mb-2 border rounded"
          />
        );
      case SectionType.TEXT:
        return (
          <ReactQuill
            value={section.content}
            onChange={(value) => handleUpdateSection(section.id, { ...section, content: value })}
            className="mb-2"
          />
        );
      case SectionType.IMAGE:
        return (
          <>
            <img src={section.content} alt="Section" className="w-full mb-2" />
          </>
        );
      case SectionType.VIDEO:
        return (
          <input
            type="text"
            value={section.content}
            onChange={(e) => handleUpdateSection(section.id, { ...section, content: e.target.value })}
            placeholder="Video URL"
            className="w-full p-2 mb-2 border rounded"
          />
        );
      case SectionType.BUTTON:
        return (
          <input
            type="text"
            value={section.content}
            onChange={(e) => handleUpdateSection(section.id, { ...section, content: e.target.value })}
            placeholder="Button Text"
            className="w-full p-2 mb-2 border rounded"
          />
        );
      case SectionType.ICON:
        return (
          <>
            <img src={section.content} alt="Icon" className="icon-preview" />
          </>
        );
      case SectionType.COLUMN:
        return (
          <div className="columns">
            <div className="column">
              <p>Column 1</p>
            </div>
            <div className="column">
              <p>Column 2</p>
            </div>
          </div>
        );
      case SectionType.SOCIAL:
        return (
          <div className="social-icons">
            <FaTwitter size={24} />
            <FaFacebook size={24} />
            <FaInstagram size={24} />
            <FaLinkedin size={24} />
          </div>
        );
      case SectionType.FORM:
        return (
          <form>
            <input type="text" placeholder="Name" className="w-full p-2 mb-2 border rounded" />
            <input type="email" placeholder="Email" className="w-full p-2 mb-2 border rounded" />
            <button type="submit" className="w-full p-2 bg-blue-500 text-white rounded">Submit</button>
          </form>
        );
      case SectionType.COUNTDOWN:
        return (
          <div className="countdown-timer">
            <p>Countdown Timer</p>
          </div>
        );
      case SectionType.MAP:
        return (
          <div className="map-embed">
            <p>Google Maps Embed</p>
          </div>
        );
      case SectionType.CHART:
        return (
          <div className="chart">
            <p>Chart Placeholder</p>
          </div>
        );
      case SectionType.QUOTE:
        return (
          <blockquote className="p-4 italic border-l-4 bg-neutral-100 text-neutral-600 border-neutral-500 quote">
            {section.content}
          </blockquote>
        );
      default:
        return null;
    }
  };

  return (
    <Draggable key={section.id} draggableId={section.id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="section p-4 mb-4 border rounded bg-white shadow-md flex flex-col space-y-2"
        >
          <div className="section-header flex justify-between items-center">
            <FaGripVertical className="text-gray-400 cursor-move" />
            <div className="section-actions flex space-x-2">
              <button onClick={() => openSettingsModal(section)}><FaCog /></button>
              <button onClick={() => handleRemoveSection(section.id)} className="text-red-600">
                <FaTrash />
              </button>
            </div>
          </div>
          {renderSectionContent()}
        </div>
      )}
    </Draggable>
  );
};

export default NewsletterEditor;
