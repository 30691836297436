// src/App.js

import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { isMobile, isTablet } from 'react-device-detect';
import Navbar from './components/Navbar';
import MobileNavbar from './components/MobileNavbar';
import Login from './components/Login';
import Register from './components/Register';
import HomePage from './Pages/Homepage';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './index.css';
import Footer from './components/Footer';
import './i18n';
import Testimonial from './Pages/Testimonial';
import Store from './Pages/Store';
import ViewCart from './Pages/ViewCart';
import ActivitiesPage from './Pages/Activities';
import BookingPage from './components/BookingPage';
import Orders from './components/Orders';
import Profile from './components/profile';
import ResetPassword from './components/ResetPassword';
import NotFound from './components/NotFound';
import Contactus from './Pages/Contactus';
import DiabetesPage from './Pages/Diabetes';
import Supportus from './Pages/Supportus';
import HistoryPage from './Pages/History';
import { useDarkMode } from './components/DarkModeContext';
import NewsletterEditor from './components/NewsletterEditor';
import Chatbot from './components/Chatbot';
import ArchivedNewsletters from './components/ArchivedNewsletters';

 // Import the new page

const stripePromise = loadStripe('your-publishable-key-here');

const App = () => {
  const { isDarkMode } = useDarkMode();
  const [notifications, setNotifications] = useState([]);

  const addNotification = (message) => {
    setNotifications((prevNotifications) => [...prevNotifications, message]);
  };

  const removeNotification = (index) => {
    setNotifications((prevNotifications) => prevNotifications.filter((_, i) => i !== index));
  };

  return (
    <div className={isDarkMode ? 'dark' : ''}>
      <Router>
        {isMobile || isTablet ? (
          <MobileNavbar notifications={notifications} addNotification={addNotification} removeNotification={removeNotification} />
        ) : (
          <Navbar notifications={notifications} addNotification={addNotification} removeNotification={removeNotification} />
        )}
        <Chatbot />
        <Routes>
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/contactus" element={<Contactus />} />
          <Route path="/" element={<HomePage addNotification={addNotification} />} />
          <Route path="/Diabetes" element={<DiabetesPage />} />
          <Route path="/History" element={<HistoryPage />} />
          <Route path="/Supportus" element={<Supportus />} />
          <Route path="/testimonial" element={<Testimonial />} />
          <Route path="/newsletter-editor" element={<NewsletterEditor />} />
          <Route path="/ArchivedNewsletters" element={<ArchivedNewsletters />} />

          <Route path="/store" element={<Store />} />
          <Route path="/ViewCart" element={
            <Elements stripe={stripePromise}>
              <ViewCart />
            </Elements>
          } />
          <Route path="/Activities" element={<ActivitiesPage />} />
          <Route path="/Booking" element={<BookingPage />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="*" element={<NotFound />} /> {/* Catch-all route */}
        </Routes>
        <Footer />
      </Router>
    </div>
  );
};

export default App;
