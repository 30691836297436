import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useCart } from '../components/contexts/CartContext';
import { useUser } from '../components/contexts/UserContext';
import AddActivityForm from '../components/AddActivityForm.js';
import EditActivityForm from '../components/EditActivityForm.js';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactModal from 'react-modal';
import { FaEdit, FaTrash, FaTimes } from 'react-icons/fa';

ReactModal.setAppElement('#root');

const ActivitiesPage = () => {
  const [activities, setActivities] = useState([]);
  const [showAddActivityForm, setShowAddActivityForm] = useState(false);
  const [showEditActivityForm, setShowEditActivityForm] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const { user, token } = useUser();
  const { addItemToCart } = useCart();
  const [notification, setNotification] = useState('');

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const headers = token ? { Authorization: `Bearer ${token}` } : {};
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/activities`, { headers });
        setActivities(response.data);
      } catch (error) {
        console.error('Failed to fetch activities:', error);
      }
    };

    fetchActivities();
  }, [token]);

  useEffect(() => {
    console.log('User:', user);
  }, [user]);

  const handleAddToCart = async (activity) => {
    try {
      const item = { activityId: activity.id, quantity: 1, activity };
      await addItemToCart(item);
      setNotification('Activity added to cart');
      setTimeout(() => {
        setNotification('');
      }, 3000); // Hide notification after 3 seconds
    } catch (error) {
      console.error('Failed to add activity to cart:', error);
      setNotification('Failed to add activity to cart');
      setTimeout(() => {
        setNotification('');
      }, 3000); // Hide notification after 3 seconds
    }
  };

  const handleActivityAdded = (newActivity) => {
    setActivities([...activities, newActivity]);
    setShowAddActivityForm(false);
  };

  const handleActivityEdited = (editedActivity) => {
    setActivities(activities.map(a => a.id === editedActivity.id ? editedActivity : a));
    setShowEditActivityForm(false);
  };

  const handleEditActivity = (activity) => {
    setSelectedActivity(activity);
    setShowEditActivityForm(true);
  };

  const handleDeleteActivity = async (activityId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/activities/${activityId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setActivities(activities.filter(a => a.id !== activityId));
      toast.success('Activity deleted successfully');
    } catch (error) {
      console.error('Failed to delete activity:', error);
      toast.error('Failed to delete activity');
    }
  };

  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setModalIsOpen(false);
  };

  return (
    <div className="container mx-auto p-4">
      <ToastContainer />
      {notification && (
        <div className="fixed top-4 left-4 bg-green-500 text-white py-2 px-4 rounded shadow-lg">
          {notification}
        </div>
      )}
      <h1 className="text-2xl font-bold mb-4">Activities</h1>
      {user && user.role === 'admin' && (
        <button
          className="mb-4 px-4 py-2 bg-green-500 text-white rounded"
          onClick={() => setShowAddActivityForm(true)}
        >
          Add New Activity
        </button>
      )}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {activities.map((activity) => (
          <div key={activity.id} className="border p-4 rounded">
            {activity.imageUrl && (
              <img
                src={`${process.env.REACT_APP_API_URL}/${activity.imageUrl}`}
                alt={activity.name}
                className="w-full h-auto rounded-lg shadow-md mb-4 cursor-pointer"
                onClick={() => openModal(`${process.env.REACT_APP_API_URL}/${activity.imageUrl}`)}
              />
            )}
            <h2 className="text-xl font-bold">{activity.name}</h2>
            <p>{activity.description}</p>
            <p>Duration: {activity.duration} minutes</p>
            <p className="text-lg font-bold">£{activity.price}</p>
            <div className="flex justify-between items-center mt-4">
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded"
                onClick={() => handleAddToCart(activity)}
              >
                Add to Cart
              </button>
              {user && user.role === 'admin' && (
                <div className="flex items-center space-x-2">
                  <button
                    className="px-4 py-2 bg-yellow-500 text-white rounded flex items-center"
                    onClick={() => handleEditActivity(activity)}
                  >
                    <FaEdit className="mr-2" /> Edit
                  </button>
                  <button
                    className="px-4 py-2 bg-red-500 text-white rounded flex items-center"
                    onClick={() => handleDeleteActivity(activity.id)}
                  >
                    <FaTrash className="mr-2" /> Delete
                  </button>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      {showAddActivityForm && (
        <AddActivityForm
          onClose={() => setShowAddActivityForm(false)}
          onActivityAdded={handleActivityAdded}
        />
      )}
      {showEditActivityForm && selectedActivity && (
        <EditActivityForm
          activity={selectedActivity}
          onClose={() => setShowEditActivityForm(false)}
          onActivityEdited={handleActivityEdited}
        />
      )}
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="fixed inset-0 bg-white flex justify-center items-center p-4"
        overlayClassName="fixed inset-0 bg-black bg-opacity-75"
      >
        {selectedImage && (
          <div className="relative" onClick={closeModal}>
            <img src={selectedImage} alt="Activity" className="max-w-full max-h-full rounded-lg shadow-lg" />
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 text-white bg-black bg-opacity-50 p-2 rounded-full"
            >
              <FaTimes className="w-6 h-6" />
            </button>
          </div>
        )}
      </ReactModal>
    </div>
  );
};

export default ActivitiesPage;
