import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { UserProvider } from './components/contexts/UserContext';
import './i18n';
import { CartProvider } from './components/contexts/CartContext';
import { Provider } from 'react-redux';
import { store } from './store';
import { DarkModeProvider } from './components/DarkModeContext'; // Import DarkModeContext

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <UserProvider>
        <CartProvider>
          <DarkModeProvider>
            <App />
          </DarkModeProvider>
        </CartProvider>
      </UserProvider>
    </Provider>
  </React.StrictMode>
);
