import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { FiSun, FiMoon, FiBell, FiHeart } from 'react-icons/fi';
import Tooltip from "@mui/material/Tooltip";
import { useUser } from './contexts/UserContext';
import { useCart } from './contexts/CartContext';
import { useDarkMode } from './DarkModeContext';
import { useDispatch } from 'react-redux';
import { startCartPolling } from '../features/cart/cartSlice';

const MobileNavbar = ({ notifications, addNotification, removeNotification }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const { user, logout } = useUser();
  const { cart } = useCart();
  const { isDarkMode, toggleDarkMode } = useDarkMode();
  const cartItemCount = cart.reduce((count, item) => count + item.quantity, 0);
  const notificationRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    startCartPolling(dispatch);
  }, [dispatch]);

  const navigation = [
    { name: "Shop", href: "/store" },
    { name: "Activities", href: "/Activities" },
    { name: "Diabetes", href: "/Diabetes" },
    { name: "Testimonial", href: "/testimonial" },
    { name: "Contact us", href: "/Contactus" },
    { name: "Support us", href: "/Supportus" },
    { name: "History", href: "/History" },
    { name: "Indived", href: "https://Indived.co.uk" },
  ];

  const handleClickOutside = (event) => {
    if (notificationRef.current && !notificationRef.current.contains(event.target)) {
      setIsNotificationsOpen(false);
    }
  };

  useEffect(() => {
    if (isNotificationsOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isNotificationsOpen]);

  return (
    <nav className={`bg-white text-gray-800 flex items-center justify-between p-4 ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-800'}`}>
      <Link to="/" className="text-lg font-bold">
        <img src="/Logo.png" alt="INDIVED Logo" className="h-8 w-auto" />
      </Link>
      <div className="flex items-center space-x-4">
        <Tooltip title="Toggle Dark Mode" arrow>
          <button onClick={toggleDarkMode} className="p-1 rounded-full text-gray-400 hover:text-white focus:outline-none" aria-label="Toggle Dark Mode">
            {isDarkMode ? <FiSun className="h-6 w-6" /> : <FiMoon className="h-6 w-6" />}
          </button>
        </Tooltip>
        <Tooltip title="Notifications" arrow>
          <div className="relative" ref={notificationRef}>
            <button 
              onClick={() => setIsNotificationsOpen(!isNotificationsOpen)}
              className="p-1 rounded-full text-gray-400 hover:text-white focus:outline-none relative" aria-label="Notifications">
              <FiBell className="h-6 w-6" />
              {notifications.length > 0 && (
                <span className="absolute top-0 right-0 h-2 w-2 rounded-full bg-red-600"></span>
              )}
            </button>
            {isNotificationsOpen && (
              <div className="origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                {notifications.map((notification, index) => (
                  <div key={index} className="px-4 py-2 text-sm text-gray-700 flex justify-between items-center">
                    <span>{notification}</span>
                    <button onClick={() => removeNotification(index)} className="text-red-500 hover:text-red-700 focus:outline-none">
                      <XIcon className="h-4 w-4" />
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        </Tooltip>
        <button onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} className="text-gray-800 focus:outline-none">
          {isMobileMenuOpen ? <XIcon className="h-6 w-6" /> : <MenuIcon className="h-6 w-6" />}
        </button>
      </div>
      <Transition show={isMobileMenuOpen} enter="transition ease-out duration-100 transform" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
        <div className="fixed inset-0 bg-gray-900 bg-opacity-75 z-50 flex items-center justify-center md:hidden">
          <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all w-full max-w-xs">
            <div className="px-4 py-5 sm:p-6">
              <div className="flex items-center justify-between">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Menu</h3>
                <button onClick={() => setIsMobileMenuOpen(false)} className="text-gray-800 focus:outline-none">
                  <XIcon className="h-6 w-6" />
                </button>
              </div>
              <div className="mt-5">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-700 hover:text-white"
                    onClick={() => setIsMobileMenuOpen(false)} // Close menu on item click
                  >
                    {item.name}
                  </Link>
                ))}
                {user ? (
                  <div>
                    <Link to="/profile" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-700 hover:text-white" onClick={() => setIsMobileMenuOpen(false)}>Profile</Link>
                    <Link to="/orders" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-700 hover:text-white" onClick={() => setIsMobileMenuOpen(false)}>Orders</Link>
                    <button onClick={logout} className="block w-full text-left px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-700 hover:text-white">Logout</button>
                  </div>
                ) : (
                  <div>
                    <Link to="/login" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-700 hover:text-white" onClick={() => setIsMobileMenuOpen(false)}>Login</Link>
                    <Link to="/register" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-700 hover:text-white" onClick={() => setIsMobileMenuOpen(false)}>Register</Link>
                  </div>
                )}
                <Link to="/ViewCart" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-700 hover:text-white" onClick={() => setIsMobileMenuOpen(false)}>
                  🛒 {cartItemCount}
                </Link>
                <Link
                  to="/Supportus#donate"
                  className="block px-3 py-2 mt-2 rounded-md text-base font-medium text-white bg-red-500 hover:bg-red-700 transition duration-150"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  Donate
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </nav>
  );
};

export default MobileNavbar;
