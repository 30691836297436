import React, { useState } from 'react';

const WidgetSettings = ({ section, onUpdateSection }) => {
  const [content, setContent] = useState(section.content);
  const [backgroundColor, setBackgroundColor] = useState(section.backgroundColor || '#ffffff');

  const handleSave = () => {
    onUpdateSection({ ...section, content, backgroundColor });
  };

  const handleIconUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setContent(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="widget-settings">
      <h2 className="text-xl font-bold mb-4">Settings</h2>
      {section.type === 'icon' ? (
        <div className="mb-4">
          <label className="block mb-2">Upload Icon</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleIconUpload}
            className="w-full p-2 border rounded"
          />
          {content && <img src={content} alt="Icon Preview" className="mt-2 w-full h-auto" />}
        </div>
      ) : (
        <div className="mb-4">
          <label className="block mb-2">Content</label>
          <textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            className="w-full p-2 border rounded"
          />
        </div>
      )}
      <div className="mb-4">
        <label className="block mb-2">Background Color</label>
        <input
          type="color"
          value={backgroundColor}
          onChange={(e) => setBackgroundColor(e.target.value)}
          className="w-full p-2 border rounded"
        />
      </div>
      <button onClick={handleSave} className="bg-blue-500 text-white px-4 py-2 rounded">
        Save
      </button>
    </div>
  );
};

export default WidgetSettings;
