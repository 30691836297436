import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

const ImageSlider = ({ slides, isAdmin, onEdit }) => {
  const baseURL = 'https://diabetesselfhelpgroup.co.uk/api/';
  const slideImages = slides[0].images.map(image => `${baseURL}${image}`);

  return (
    <div className="image-slider max-w-3xl mx-auto my-8">
      {isAdmin && (
        <div className="flex justify-end mb-4">
          <button onClick={onEdit} className="bg-blue-600 text-white px-4 py-2 rounded">
            Edit
          </button>
        </div>
      )}
      <Slide easing="ease" duration={5000} arrows={true} autoplay>
        {slideImages.map((image, index) => (
          <div key={index} className="each-slide relative">
            <img
              src={image}
              alt={`Slide ${index}`}
              className="w-full h-64 object-cover rounded-lg shadow-lg"
            />
            <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 p-4 rounded-b-lg">
              <h3 className="text-2xl font-bold text-white">{slides[0].title}</h3>
              <p className="text-lg text-white">{slides[0].text}</p>
              {slides[0].link && (
                <a href={slides[0].link} className="text-blue-300 hover:underline">
                  Learn More
                </a>
              )}
            </div>
          </div>
        ))}
      </Slide>
    </div>
  );
};

export default ImageSlider;
